import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Notfound = () => {
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return <div>Notfound</div>;
};

export default Notfound;
