import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { MyDocument } from "../components/MyDocument";
import "./pdfViewer.css";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import expobazarLogo from '../assets/expobazarLogo.png';
import { expoUrl } from "../config/config";
import europeLogo from '../assets/expoLogoEurope.png';



function UsaConfirmRegistration() {
  let location = useLocation();
  let data = location.state || {};
  const formData = data.formData;
  const navigate = useNavigate();
  const pdfRef = useRef();
  const sigCanvas = useRef();


  const [w9formLink, setw9formLink] = useState();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [message, setMessage] = useState('');
  const [showSignaturePopup, setShowSignaturePopup] = useState(false);
  const [showThankyouPopup, setShowThankyouPopup] = useState(false);
  const [penColor, setPenColor] = useState("black");
  const [signURL, setSignURL] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [ipAddress, setIpAddress] = useState("")
  console.log("Public IP address ==>>", ipAddress);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const goToHomePage = () => {
    window.location.href = 'https://usa.expobazaar.com/account';
  };

  console.log(isChecked);


  const saveFormDataToLocalStorage = () => {
    if (formData) {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
  };

  const handleBack = () => {
    navigate("/usa/registration");
  };
  const handleCrossClicked = () => {
    navigate("/usa/registration");
  };



  console.log("ConfirmRegistration", formData);

  // const generateAndSendPdf = async () => {
  //   const blob = await pdf(<MyDocument />).toBlob();

  //   // Create FormData and append the PDF blob
  //   const formData = new FormData();
  //   formData.append('file', blob, 'generated.pdf');

  //   // Send the PDF to the API
  //   axios.post('https://expobazaar.onrender.com/media/upload', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   })
  //     .then(response => {
  //       console.log('PDF URL:', response?.data?.fileUrl);
  //       setw9formLink(response?.data?.fileUrl);
  //     })
  //     .catch(error => {
  //       console.error('Error uploading PDF:', error);
  //     });
  // };

  // function getLocalIPAddresses(callback) {
  //   let ipAddresses = [];

  //   // Create a new RTCPeerConnection
  //   const rtcPeerConnection = new RTCPeerConnection({iceServers: []});

  //   // Create a data channel
  //   rtcPeerConnection.createDataChannel("");

  //   // Create an offer
  //   rtcPeerConnection.createOffer()
  //     .then(offer => rtcPeerConnection.setLocalDescription(offer))
  //     .catch(err => console.error(err));

  //   // Listen for ICE candidates
  //   rtcPeerConnection.onicecandidate = (event) => {
  //     if (event.candidate) {
  //       // Extract the IP address from the candidate
  //       const ip = /([0-9]{1,3}(\.[0-9]{1,3}){3})/g.exec(event.candidate.candidate);
  //       // if (ip) {
  //       //   ipAddresses.push(ip[1]);
  //       // }
  //       if (ip && !ipAddresses.includes(ip[1])) {
  //         ipAddresses.push(ip[1]);
  //       }
  //     } else {
  //       // No more candidates, return the list of IP addresses
  //       callback(ipAddresses);
  //     }
  //   };
  // }

  // function getLocalIPAddresses(callback) {
  //   let localIPAddress = null;

  //   // Create a new RTCPeerConnection
  //   const rtcPeerConnection = new RTCPeerConnection({iceServers: []});

  //   // Create a data channel (not used, but necessary to create an offer)
  //   rtcPeerConnection.createDataChannel("");

  //   // Create an offer
  //   rtcPeerConnection.createOffer()
  //     .then(offer => rtcPeerConnection.setLocalDescription(offer))
  //     .catch(err => console.error(err));

  //   // Listen for ICE candidates
  //   rtcPeerConnection.onicecandidate = (event) => {
  //     if (event.candidate) {
  //       // Extract the IP address from the candidate
  //       const ip = /([0-9]{1,3}(\.[0-9]{1,3}){3})/g.exec(event.candidate.candidate);
  //       if (ip && !localIPAddress) {
  //         localIPAddress = ip[1];
  //         // Once we have an IP address, stop gathering more candidates
  //         rtcPeerConnection.onicecandidate = null;
  //         callback(localIPAddress);
  //       }
  //     } else {
  //       // If no more candidates and no IP address found, return null
  //       if (!localIPAddress) {
  //         callback(null);
  //       }
  //     }
  //   };
  // }


  const getIpAddresses = () => {
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        console.log("Public IP Address:", response.data.ip);
        setIpAddress(response.data.ip);

      })
      .catch(error => {
        console.error("Error fetching IP address:", error);
      });
  };





  const generateAndSendPdf = async () => {
    const blob = await pdf(<MyDocument formData={formData} signURL={signURL} />).toBlob();

    // Create FormData and append the PDF blob
    const uploadFormData = new FormData();
    uploadFormData.append('file', blob, 'generated.pdf');

    // Send the PDF to the API
    axios.post(`${expoUrl}/api/awsmedia/single`, uploadFormData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log('PDF URL:', response?.data?.fileUrl);
        setw9formLink(response?.data?.fileUrl);
      })
      .catch(error => {
        console.error('Error uploading PDF:', error);
      });
  };

  useEffect(() => {
    saveFormDataToLocalStorage();
    // generateAndSendPdf();
  }, []);
  useEffect(() => {
    // getLocalIPAddresses((ipAddresses) => {
    //   console.log('Local IP addresses:', ipAddresses);
    //   setIpAddress(ipAddresses);
    getIpAddresses();
  }, []);
  useEffect(() => {
    if (signURL) {
      generateAndSendPdf();
    }
  }, [signURL]);
  useEffect(() => {
    if (message !== "") {
      setButtonClicked(false);
    }
  }, [message]);

  const handleSign = () => {
    setShowSignaturePopup(true);
  };
  const handleClose = () => {
    setShowSignaturePopup(false);
  }
  const download = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    console.log(URL);
    setSignURL(URL); // Set download URL state
    setShowSignaturePopup(false);
  };

  // const handleSubmit = async (e) => {

  //   console.log("w9formLink", w9formLink);
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       "https://expobazaar.onrender.com/companies/create",
  //       {
  //         company: {
  //           name: data.formData.companyName,
  //         },
  //         companyLocation: {
  //           name: "Address",
  //           billingAddress: {
  //             firstName: data.formData.firstName,
  //             lastName: data.formData.lastName,
  //             address1: data.formData.street1,
  //             address2: data.formData.street2,
  //             city: data.formData.city,
  //             zip: data.formData.zipCode,
  //             countryCode: data.formData.country,
  //           },
  //         },
  //         taxId: data.formData.taxId,
  //         companyContact: {
  //           email: data.formData.primaryEmail,
  //           firstName: data.formData.firstName,
  //           lastName: data.formData.lastName,
  //           phone: data.formData.primaryPhone,
  //         },
  //         ssn_no: data.formData.ssn,
  //         dock_door: data.formData.dockDoor,
  //         business_type: data.formData.businessType,
  //         w9_form: w9formLink,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "X-Shopify-Access-Token": "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",
  //         },
  //       }
  //     );
  //     console.log("Success", response);
  //     navigate("/thankyou");

  //     // console.log("Response:", response.config.data);
  //     // navigate("/confirmation",state:{});
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };


  // const handleSubmit = async (e) => {
  //   console.log("w9formLink", w9formLink);
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       "https://expobazaar.onrender.com/companies/create",
  //       {
  //         company: {
  //           name: data?.formData?.companyName,
  //         },
  //         companyLocation: {
  //           name: "Address",
  //           billingAddress: {
  //             firstName: data?.formData.firstName,
  //             lastName: data.formData.lastName,
  //             address1: data.formData.street1,
  //             address2: data.formData.street2,
  //             city: data.formData.city,
  //             zip: data.formData.zipCode,
  //             countryCode: data.formData.country,
  //           },
  //         },
  //         taxId: data.formData.taxId,
  //         companyContact: {
  //           email: data.formData.primaryEmail,
  //           firstName: data.formData.firstName,
  //           lastName: data.formData.lastName,
  //           phone: data.formData.primaryPhone,
  //         },
  //         ssn_no: data.formData.ssn,
  //         dock_door: data.formData.dockDoor,
  //         business_type: data.formData.businessType,
  //         w9_form: w9formLink,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "X-Shopify-Access-Token": "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",
  //         },
  //       }
  //     );

  //     if (response.status === 200) { // Check if the response is successful
  //       console.log("Success", response);
  //       navigate("/thankyou");
  //     } else {
  //       console.error("Unexpected response status:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }; 


  const handleSubmit = async (e) => {
    setButtonClicked(true);
    console.log("w9formLink", w9formLink);
    e.preventDefault();

    // Ensure all form data is present
    const formData = data?.formData;
    if (!formData) {
      console.error("Form data is missing");
      return;
    }



    const requestData = {
      company: {
        name: formData.businessName,
      },
      companyLocation: {
        name: formData.city,
        billingAddress: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address1: formData.street1,
          address2: formData.street2,
          city: formData.city,
          zip: formData.zipCode,
          countryCode: formData.country,
        },
      },
      taxId: formData.taxId,
      companyContact: {
        email: formData.primaryEmail,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.primaryPhone,
      },
      ssn_no: formData.ssn,
      dock_door: formData.dockDoor,
      business: formData.business,
      w9_form: w9formLink,
      ipAddress: ipAddress,
      userpassword: formData.password,
    };

    console.log("Request Data:", requestData);

    try {
      const response = await axios.post(
        `${expoUrl}/api/usa/companies/create`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) { // Check if the response is successful
        console.log("Success", response);
        // navigate("/thankyou");
        localStorage.removeItem("formData");
        setShowThankyouPopup(true);
        setButtonClicked(false);
      } else {
        // console.error("Unexpected response status:", response.status);
        console.log("Unexpected response status:", response.status);
        setMessage(response?.data?.message);
        // console.log("response",response?.data?.Error[0]?.message)
      }
    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response.data);
        console.log("Error Response:", error?.response?.data);
        console.error("Status:", error.response.status);
        console.log("Status:", error?.response?.status);
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error Request:", error.request);
        console.log("Error Request:", error?.request);
      } else {
        // Something happened in setting up the request
        console.error("Error Message:", error.message);
        console.log("Error Message:", error);
      }
    }
  };


  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-center text-2xl mt-4 mb-8">Create a Business Account</h1>
      <section className="border p-4 md:p-8 mb-6 rounded-lg businessInfo">
        <p className="mb-4">
          As a Buyer, you'll enjoy exclusive pricing and access to a wide range of selected home and lifestyle brands. Our unique collection combines designs and patterns inspired by Indian artistry, offering products with global appeal.
        </p>
        <p className="mb-4">
          Our dedicated sales team will work with you to curate home and lifestyle products that meet your specific sourcing requirements.
        </p>
        <p className="">
          To speed up registration, please have the following information ready:
        </p>
        <ul className="list-disc list-inside mb-6">
          <li>
            <b>Company and contact details</b>

          </li>
          <li>
            <b>Billing and tax information</b>
          </li>
        </ul>
        <p className="mb-4">
          For common questions, please visit our {" "}
          <a href="https://expobazaarus.myshopify.com/pages/faq" className="underline">
            <b>FAQ page.</b>
          </a>
          If you're an existing ExpoBazaar customer and need help, email us at{" "}
          <a href="mailto:info@expobazaar.com" className="underline">
            <b>info@expobazaar.com</b>
          </a>{" "}
          . For any other inquiries, please submit a request.
        </p>
        <p className="">
          <b>Important: </b> To receive tax exemption, you'll need to digitally sign a W9 form during registration process.
        </p>
      </section>

      <div className="pdf-viewer-container">
        <PDFViewer ref={pdfRef} showToolbar={false} className="pdf-viewer">
          <MyDocument formData={formData} signURL={signURL} />
        </PDFViewer>
      </div>

      <div>
        <p className="text-red-600 mt-7">{message}</p>
      </div>


      <div className="container mx-auto px-4 py-6 relative">
        {showSignaturePopup && (
          <>
            <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl border w-60vw h-60vh w-5/6 md:w-3/5 md:h-3/5">
              <div className="w-full h-full bg-white rounded-xl border p-1 md:p-4">
                {/* <button
                className="absolute top-0 right-0 m-2 text-gray-700 hover:text-gray-900"
                onClick={() => handleClose(false)}
              >
                Close
              </button> */}
                <div className="flex justify-center align-middle">
                  <h2 className="text-center text-2xl ">Please Sign Here</h2>
                </div>
                <div className="bg-zinc-50 h-64 w-full rounded-xl border ">

                  <SignatureCanvas
                    penColor={"black"}
                    canvasProps={{ className: "w-full h-full" }}
                    ref={sigCanvas}
                    className="" />
                </div>
                <div className="flex justify-center gap-5 mt-10 w-full items-center font-medium tracking-wider text-white md:text-base text-xs">
                  <button
                    className="subBtn text-white py-2 px-4 rounded"
                    onClick={() => handleClose(false)}
                  >
                    Close
                  </button>
                  <button
                    className="subBtn text-white py-2 px-4 rounded"
                    onClick={() => sigCanvas.current.clear()}
                  >
                    Retry
                  </button>
                  <button
                    className="subBtn text-white py-2 px-4 rounded"
                    onClick={download}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </>

        )}
        <div className="container mx-auto px-4 py-6 relative">
          {showThankyouPopup === true && (
            <>
              {/* <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  w-90 pl-8 pr-8 pt-10 pb-10 text-center">
                <button className="text-4xl absolute right-4 top-4" onClick={handleCrossClicked}>&times;</button>
                <img src={expobazarLogo} alt="Expo Bazaar Logo" className="mx-auto mb-4 w-40 " />
                <div className='lastPageContainer pt-5 pb-5'>
                  <h1 className="text-2xl font-semibold mb-2 ">Thank You</h1>
                  <h1 className="text-2xl font-semibold mb-4">for Registering with us!</h1>
                </div>
                <button className="thankyou-subBtn px-10 py-2  text-white rounded-full text-sm " onClick={goToHomePage}>
                  Get Started
                </button>
                <p className="thankyouQuestion mt-4 text-xs">
                  <span className='font-bold'>Questions? Contact us:</span><span > info@expobazaar.com</span>
                </p>
              </div> */}
              <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-96 pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center">
                <button className="text-3xl md:text-4xl absolute right-4 top-4" onClick={handleCrossClicked}>&times;</button>
                <img src={europeLogo} alt="Expo Bazaar Logo" className="mx-auto mb-4 w-32 md:w-40" />
                <div className='lastPageContainer pt-4 pb-4 md:pt-5 md:pb-5'>
                  <h1 className="text-xl md:text-2xl font-semibold mb-2">Thank You</h1>
                  <h1 className="text-xl md:text-2xl font-semibold mb-4">for Registering with us!</h1>
                </div>
                <button className="px-8 py-2 bg-[#535454] text-white border-[1px] border-[#535454] hover:bg-white hover:text-[#535454] rounded-full text-sm hover:font-bold" onClick={goToHomePage}>
                  Get Started
                </button>
                <p className="thankyouQuestion mt-4 text-xs">
                  <span className='font-bold'>Questions? Contact us:</span><span> info@expobazaar.com</span>
                </p>
              </div>

            </>
          )}
        </div>

        <div className="flex justify-center">
          <button
            type="button"
            // className="bg-white border border-gray-300 text-gray-700 py-2 px-12 rounded hover:bg-gray-100"
            className="bg-white border border-[#535454] text-[#535454] py-2 px-12 rounded hover:bg-[#535454] hover:text-white"
            onClick={() => handleSign(true)}
          >
            Please sign Here
          </button>
        </div>
      </div>



      <form>
        {/* <div className="flex items-center my-4">
          <input
            id="agree"
            type="checkbox"
            className="form-checkbox h-4 w-4 text-blue-600"
            onChange={handleCheckboxChange}
          />
          <label htmlFor="agree" className="ml-2 text-gray-700 bazaarPolicy">
            By continuing, you I agree to ExpoBazaar's{" "}
            <a href="https://expobazaarus.myshopify.com/pages/our-policy" className="underline">
              <b>Privacy Policy </b>
            </a>{" "}
            and{" "}
            <a href="https://expobazaarus.myshopify.com/policies/terms-of-service" className="underline">
              <b>Terms of Use</b>
            </a>
            .
          </label>
        </div> */}

        <div className="flex items-center my-4">
          <input
            id="agree"
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="hidden"
          />
          <label htmlFor="agree" className="custom-checkbox-label flex items-center">
            <div className={`custom-checkbox ${isChecked ? 'checked' : ''}`}></div>
            <span className="ml-2 text-gray-700 bazaarPolicy">
              By continuing, you agree to ExpoBazaar's{" "}
              <a href="https://expobazaarus.myshopify.com/pages/our-policy" className="underline">
                <b>Privacy Policy</b>
              </a>{" "}
              and{" "}
              <a href="https://expobazaarus.myshopify.com/policies/terms-of-service" className="underline">
                <b>Terms of Use</b>
              </a>
              .
            </span>
          </label>
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            // className="bg-white border border-gray-300 text-gray-700 py-2 px-12 rounded hover:bg-gray-100"
            className="bg-white border border-[#535454] text-[#535454] py-2 px-12 rounded hover:bg-[#535454] hover:text-white"
            onClick={handleBack}
          >
            Back
          </button>
          {w9formLink ? (
            buttonClicked ? (
              <button
                disabled
                type="button"
                className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"

                  />
                </svg>
                Please Wait
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleSubmit}
                className={`py-2 px-12 rounded ${isChecked && signURL
                  ? 'bg-[#535454] text-white border-[1px] rounded-[10px] border-[#535454] hover:bg-white hover:text-[#535454] hover:font-semibold'
                  : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-100'
                  }`}
                disabled={!isChecked || !signURL || message !== ""}
              >
                {showThankyouPopup === false ? "Submit" : "Submitted"}
              </button>
            )

          ) : (
            !signURL ? (
              <button
                disabled
                type="button"
                className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
                Please Sign to proceed
              </button>
            )
              : (
                <button
                  disabled
                  type="button"
                  className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  Please read the document carefully
                </button>
              )
          )}
          {/* {
            !signURL ? (
              <button
                disabled
                type="button"
                className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
                Please Sign to proceed
              </button>
            ) 
            : (
              <button
              type="submit"
              onClick={handleSubmit}
              className={`py-2 px-12 rounded ${isCheckboxChecked && signURL
                  ? 'subBtn text-white'
                  : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-100'
                }`}
              disabled={!isCheckboxChecked || !signURL}
            >
              Submit
            </button>
           )} */}


        </div>
      </form>
    </div>
  );
}

export default UsaConfirmRegistration;