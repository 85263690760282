import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
// import { parsePhoneNumber } from 'awesome-phonenumber';
// import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import * as EmailValidator from 'email-validator';
import { expoUrl } from "../config/config";
import uploadIcon from '../assets/uploadIcon.png';
// import infoIcon from '../assets/infoIcon.png';
// import { useTranslation } from 'react-i18next';
import { useFlag } from '../contexts/FlagContext';
import { CiCircleCheck } from "react-icons/ci";
import { GoInfo } from "react-icons/go";
// import expobazarLogo from '../assets/expobazarLogo.png';
// import { Tooltip } from 'react-tooltip';
import europeLogo from '../assets/expoLogoEurope.png';
// const postalCodes = require('postal-codes-js');
import { Tooltip } from 'react-tooltip';
import infoIcon from '../assets/infoIcon.png';



function IndiaRegistrationForm() {
    // const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        ownerName: "",
        business: "",
        businessName: "",
        addressline1: "",
        addressline2: "",
        postalCode: "",
        city: "",
        state: "",
        country: "IN",
        prefix: "",
        firstName: "",
        lastName: "",
        primaryEmail: "",
        // language: "dutch",
        primaryPhone: "",
        telephone: "",
        gstNumber: "",
        panNumber: "",
        gstDocument: "",
    });

    console.log(formData)

    // const countryCodes = {
    //     Austria: "AT",
    //     Belgium: 'BE',
    //     Netherlands: 'NL',
    //     Bulgaria: 'BG',
    //     Cyprus: 'CY',
    //     Estonia: 'EE',
    //     Finland: 'FI',
    //     Iceland: 'IS',
    //     Ireland: 'IE',
    //     Latvia: 'LV',
    //     Lithuania: 'LT',
    //     Malta: 'MT',
    //     Norway: 'NO',
    //     Sweden: 'SE',
    //     Turkey: 'TR',
    //     'United Kingdom': 'GB',
    //     Croatia: 'HR',
    //     'Czech Republic': 'CZ',
    //     Denmark: 'DK',
    //     Germany: 'DE',
    //     Hungary: 'HU',
    //     Poland: 'PL',
    //     Slovakia: 'SK',
    //     Switzerland: 'CH',
    //     France: 'FR',
    //     Greece: 'GR',
    //     Luxembourg: 'LU',
    //     Romania: 'RO',
    //     Italy: 'IT',
    //     Slovenia: 'SI',
    //     Portugal: 'PT',
    //     Spain: 'ES'
    // };

    // Define regex patterns for European countries
    // const postalCodePatterns = {
    //     AT: /^[0-9]{4}$/, // Austria
    //     BE: /^[0-9]{4}$/, // Belgium
    //     NL: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/, // Netherlands
    //     BG: /^[0-9]{4}$/, // Bulgaria
    //     CY: /^[0-9]{4}$/, // Cyprus
    //     EE: /^[0-9]{5}$/, // Estonia
    //     FI: /^[0-9]{5}$/, // Finland
    //     IS: /^[1-9][0-9]{2}$/, // Iceland
    //     IE: /^[A-Z0-9]{2}\s?[0-9][A-Z]{2}$/i, // Ireland
    //     LV: /^[0-9]{4}$/, // Latvia
    //     LT: /^[0-9]{5}$/, // Lithuania
    //     MT: /^[A-Z]{3}\s?[0-9]{4}$/, // Malta
    //     NO: /^[0-9]{4}$/, // Norway
    //     SE: /^[0-9]{3}\s?[0-9]{2}$/, // Sweden
    //     TR: /^[0-9]{5}$/, // Turkey
    //     GB: /^[A-Z]{1,2}[0-9R][0-9A-Z]? \d[A-Z]{2}$/i, // United Kingdom
    //     HR: /^[0-9]{5}$/, // Croatia
    //     CZ: /^[0-9]{5}$/, // Czech Republic
    //     DK: /^[0-9]{4}$/, // Denmark
    //     DE: /^[0-9]{5}$/, // Germany
    //     HU: /^[0-9]{4}$/, // Hungary
    //     PL: /^[0-9]{2}-[0-9]{3}$/, // Poland
    //     SK: /^[0-9]{5}$/, // Slovakia
    //     CH: /^[0-9]{4}$/, // Switzerland
    //     FR: /^[0-9]{5}$/, // France
    //     GR: /^[0-9]{5}$/, // Greece
    //     LU: /^[LUX]\d{4}$/, // Luxembourg
    //     RO: /^[0-9]{6}$/, // Romania
    //     IT: /^[0-9]{5}$/, // Italy
    //     SI: /^[0-9]{4}$/, // Slovenia
    //     PT: /^[0-9]{4}-[0-9]{3}$/, // Portugal
    //     ES: /^[0-9]{5}$/, // Spain
    // };



    // const languageGroups = {
    //     dutch: ['Belgium', 'Netherlands'],
    //     english: ['Bulgaria', 'Cyprus', 'Estonia', 'Finland', 'Iceland', 'Ireland', 'Latvia', 'Lithuania', 'Malta', 'Norway', 'Sweden', 'Turkey', 'United Kingdom'],
    //     german: ['Austria', 'Croatia', 'Czech Republic', 'Denmark', 'Germany', 'Hungary', 'Poland', 'Slovakia', 'Switzerland'],
    //     french: ['France', 'Greece', 'Luxembourg', 'Romania'],
    //     italian: ['Italy', 'Slovenia'],
    //     spanish: ['Portugal', 'Spain']
    // };

    // const countryTooltipMap = {
    //     AT: "Austrian Federal Economic Chamber (WKO)",
    //     BE: "Federation of Belgian Chambers of Commerce (FEB/VBO)",
    //     BG: "Bulgarian Chamber of Commerce and Industry (BCCI)",
    //     HR: "Croatian Chamber of Economy (HGK)",
    //     CY: "Cyprus Chamber of Commerce and Industry (KEVE)",
    //     CZ: "Czech Chamber of Commerce (HK ČR)",
    //     DK: "Danish Chamber of Commerce (Dansk Erhverv)",
    //     EE: "Estonian Chamber of Commerce and Industry (ECCI)",
    //     FI: "Finland Chamber of Commerce",
    //     FR: "Mouvement des Entreprises de France (MEDEF)",
    //     DE: "Association of German Chambers of Industry and Commerce (DIHK)",
    //     GR: "Hellenic Chamber of Hotels",
    //     HU: "Hungarian Chamber of Commerce and Industry (MKIK)",
    //     IS: "Icelandic Chamber of Commerce",
    //     IE: "Chambers Ireland",
    //     IT: "Italian Chamber of Commerce (Unioncamere)",
    //     LV: "Latvian Chamber of Commerce and Industry (LTRK)",
    //     LT: "Association of Lithuanian Chambers of Commerce, Industry and Crafts (ALCCIC)",
    //     LU: "Chamber of Commerce of the Grand Duchy of Luxembourg",
    //     MT: "The Malta Chamber of Commerce, Enterprise and Industry",
    //     NL: "Netherlands Chamber of Commerce (KVK)",
    //     NO: "The Confederation of Norwegian Enterprise (NHO)",
    //     PL: "Polish Chamber of Commerce",
    //     PT: "Portuguese Chamber of Commerce and Industry (CCIP)",
    //     RO: "Romanian Chamber of Commerce and Industry (CCIR)",
    //     SK: "Slovak Chamber of Commerce and Industry (SOPK)",
    //     SI: "Chamber of Commerce and Industry of Slovenia (CCIS)",
    //     ES: "Spanish Chamber of Commerce (Cámara de Comercio de España)",
    //     SE: "Swedish Chamber of Commerce",
    //     CH: "Swiss Federation of Commerce and Industry (economiesuisse)",
    //     TR: "Union of Chambers and Commodity Exchanges of Turkey (TOBB)",
    //     GB: "British Chambers of Commerce (BCC)"
    // };




    const [searchCompanyLoading, setSearchCompanyLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [isSoleProprietorship, setIsSoleProprietorship] = useState(false)
    const [companyFound, setCompanyFound] = useState(null);
    const [companyFoundMessage, setCompanyFoundMessage] = useState("");
    const [showThankyouPopup, setShowThankyouPopup] = useState(false);
    const [ipAddress, setIpAddress] = useState("");
    const [pdfUploaded, setPdfUploaded] = useState(null);
    const [pdfLoader, setPdfLoader] = useState(false);
    const [gstValid, setGstValid] = useState(null);
    const [gstValidMessage, setGstValidMessage] = useState("");
    const [gstValidErrorMessage, setGstValidErrorMessage] = useState("");
    const [gstCompanyFoundMessage, setGstCompanyFoundMessage] = useState("");
    const [gstNumberLoader, setGstNumberLoader] = useState(false);
    const [panValid, setPanValid] = useState(null);
    const [panUsername, setPanUsername] = useState("");
    const [panNumberLoader, setPanNumberLoader] = useState(false);
    const [panMessage, setPanMessage] = useState("");
    const [submitLoader, setSubmitLoader] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [pdfLink, setPdfLink] = useState("");
    const [haveGst, setHaveGst] = useState(true);
    console.log("HAVE GST :", haveGst)

    const handleGstChange = (value) => {
        setHaveGst(value);
    };

    console.log("PDF LINK", pdfLink)
    console.log(gstValid);
    console.log("Company found ====>", companyFound);
    console.log("Company found message====>", companyFoundMessage);

    console.log("Public IP address ==>>", ipAddress);
    const goToHomePage = () => {
        window.location.href = 'https://expobazaarin.myshopify.com/account';
    };

    const handleCrossClicked = (e) => {
        setShowThankyouPopup(false);
        setButtonDisable(false);
        window.location.reload(); // This will refresh the page
    };


    // const getIpAddresses = () => {
    //     axios.get('https://api.ipify.org?format=json')
    //         .then(response => {
    //             console.log("Public IP Address:", response.data.ip);
    //             setIpAddress(response.data.ip);

    //         })
    //         .catch(error => {
    //             console.error("Error fetching IP address:", error);
    //         });
    // };

    console.log("Company found state ", companyFound);
    const { selectedFlag } = useFlag();

    // useEffect(() => {
    //     i18n.changeLanguage(selectedFlag);
    // }, [selectedFlag]);

    // useEffect(() => {
    //     const storedFormData = JSON.parse(localStorage.getItem("formData"));
    //     if (storedFormData) {
    //         setFormData(storedFormData);
    //     }
    // }, []);
    // useEffect(() => {
    //     getIpAddresses();
    // }, []);
    useEffect(() => {
        if (errorMessage !== "") {
            setSubmitLoader(false);
        }
    }, [errorMessage]);
    useEffect(() => {
        if (formData.business === "sole-proprietorship") {
            setIsSoleProprietorship(true);
        }
    });

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const requestData = {
        company: {
            name: formData.businessName,
        },
        companyLocation: {
            name: formData.city,
            billingAddress: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                address1: formData.addressline1,
                address2: formData.addressline2,
                city: formData.city,
                zip: formData.postalCode,
                countryCode: formData.country,
            },
        },
        // taxId: formData.gstNumber,
        companyContact: {
            email: formData.primaryEmail,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.primaryPhone,
        },
        GST: formData.gstNumber,
        panNumber: formData.panNumber,
        GstDocument: pdfLink,
        businessType: formData.business,
        business: formData.businessName,
        // ipAddress: ipAddress,
        // language: formData.language,
    };

    console.log(requestData);

    const validateGstNumber = async (e) => {
        e.preventDefault();
        setGstNumberLoader(true);

        try {
            const response = await fetch(`${expoUrl}/api/gst/validate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ GST: formData.gstNumber }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log("Result is :::::::::::===>>>>>", result)
                // setGstValid(result?.data?.data?.valid);
                setGstValid(result?.success);
                console.log("GST Valid is : ", result?.success)
                setGstNumberLoader(false);

                if (result?.success) {
                    setGstValidMessage(result?.data?.data?.legal_name)
                    // console.log("Result is :::::::::::===>>>>>", result);
                    searchCompanyAPI(formData.gstNumber);
                } else {
                    // const message = t('errors.gstCompanyFoundMessage');
                    const message = "Invalid GST Number, Please fill Valid GST Number";
                    setGstValid(false);
                    // setErrorMessage(message);
                    setGstCompanyFoundMessage(message);
                    console.log(message);
                }
            } else {
                console.error('Failed to validate GST number');
                setGstValid(false);
                setGstNumberLoader(false);
                setGstValidErrorMessage("GST is Invalid.")
            }
        } catch (error) {
            console.error('Error occurred during GST validation:', error);
            setGstValid(false);
            setGstNumberLoader(false);
        }
    };
    const validatePanNumber = async (e) => {
        setPanNumberLoader(true);
        try {
            const response = await fetch(`${expoUrl}/api/pan/validate/${formData.panNumber}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                console.log(" PAN Result is :::::::::::===>>>>>", result);

                // Assuming result contains a 'flag' property for valid PAN
                console.log("PAN Valid is : ", result);
                setPanNumberLoader(false);

                if (result?.success === true) {
                    setPanValid(result?.success)
                    setPanUsername(result?.response?.data?.full_name);
                    console.log("PAN CARD RESULT IS ", result?.response?.data?.full_name)
                } else {
                    setPanValid(false);
                    setPanUsername("");

                }

                // if (result?.data?.flag) {
                //     // If PAN is valid, proceed with the next step
                //     searchCompanyAPI(formData.panNumber);
                // } else {
                //     // Handle the invalid PAN case
                //     const message = result?.data?.message;
                //     setPanValid(false);
                //     setPanMessage(message);
                //     console.log(message);
                // }
            } else {
                console.error('Failed to validate PAN number');
                setErrorMessage('Invalid PAN Number, Please fill valid PAN Number')
                setPanValid(false);
                setPanNumberLoader(false);
            }
        } catch (error) {
            console.error('Error occurred during PAN validation:', error);
            setPanValid(false);
            setPanNumberLoader(false);
        }
    };



    const uploadGstDocument = async (file) => {
        setPdfLoader(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${expoUrl}/api/awsmedia/india/taxdoc`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                setPdfUploaded(true);
                setPdfLoader(false);
                setPdfLink(response?.data?.fileUrl);
            } else {
                console.log("Unexpected response status:", response.status);
                setPdfUploaded(false);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const createAccountAPI = async (e) => {
        setSubmitLoader(true);
        console.log(requestData);
        try {
            const response = await axios.post(
                `${expoUrl}/api/india/companies/create`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                console.log("Success", response);
                setSubmitLoader(false);
                setShowThankyouPopup(true);
                // if(response.success === false ){
                //     console.log(response.message);
                // }
            } else {
                console.log("Unexpected response status:", response.status);
                setErrorMessage(response?.data?.Error[0]?.message);
                setButtonDisable(false);
            }
        } catch (error) {
            if (error.response) {
                console.error("Error Response:", error.response.data);
                console.log("Error Response:", error?.response?.data);
                console.error("Status:", error.response.status);
                console.log("Status:", error?.response?.status);
                setButtonDisable(false);

            } else if (error.request) {
                // Request was made but no response received
                console.error("Error Request:", error.request);
                console.log("Error Request:", error?.request);
                setButtonDisable(false);

            } else {
                // Something happened in setting up the request
                console.error("Error Message:", error.message);
                console.log("Error Message:", error);
                setButtonDisable(false);

            }
        }
    }

    // const handleChange = (e) => {
    //     const { id, value } = e.target;

    //     // Update formData based on id and value
    //     let newValue = value;

    //     if (id === "business") {
    //         setIsSoleProprietorship(value === "sole-proprietorship");
    //     } else if (id === "primaryPhone" || id === "telephone") {
    //         newValue = value.replace(/\D/g, "").slice(0, 10);
    //     }else if (id === "postalCode") {
    //         newValue = value.replace(/\D/g, "").slice(0, 5);
    //     }

    //     if (id === 'gstNumber') {
    //         setGstValid(null);
    //         setCompanyFound(null);
    //         setGstCompanyFoundMessage("");
    //         setGstValidMessage("");
    //         setGstValidErrorMessage("");
    //     }

    //     if (id === 'panNumber') {
    //         setPanUsername("");
    //     };

    //     if (id === 'gstDocument') {
    //         uploadGstDocument(e.target.files[0]);

    //     }

    //     console.log(formData)

    //     setFormData({ ...formData, [id]: value })

    //     setErrorMessage("");
    // };

    const handleChange = (e) => {
        const { id, value, files } = e.target;
        let newValue = value;

        // Handle specific input updates based on id
        switch (id) {
            case "business":
                setIsSoleProprietorship(newValue === "sole-proprietorship");
                break;
            case "primaryPhone":
            case "telephone":
                newValue = newValue.replace(/\D/g, "").slice(0, 10);
                break;
            case "postalCode":
                newValue = newValue.replace(/\D/g, "");
                break;
            case "gstNumber":
                setGstValid(null);
                setCompanyFound(null);
                setGstCompanyFoundMessage("");
                setGstValidMessage("");
                setGstValidErrorMessage("");
                break;
            case "panNumber":
                setPanUsername("");
                break;
            case "gstDocument":
                if (files && files[0]) {
                    uploadGstDocument(files[0]);
                }
                break;
            default:
                break;
        }

        // Update formData
        setFormData((prevData) => ({ ...prevData, [id]: newValue }));

        // Clear error message
        setErrorMessage("");

        console.log("FORMDATA IS :", formData);
    };


    useEffect(() => {
        if (formData?.panNumber?.length === 10) {
            validatePanNumber();  // Call the API only when panNumber is exactly 10 characters
        }
    }, [formData.panNumber]);


    const searchCompanyAPI = async (num) => {
        setSearchCompanyLoading(true);
        try {
            const response = await axios.get(`${expoUrl}/api/india/companies/companysearch/${num}`);
            console.log("searchCompanyresponse =>>>", response)
            setCompanyFound(response.data.success);
            setCompanyFoundMessage(response.data.message);
        } catch (error) {
            console.error(error);
            setCompanyFound(false);

        } finally {
            setSearchCompanyLoading(false);
        }
    };

    const sendDataToSearchAPI = async () => {
        try {
            const response = await axios.post(
                `${expoUrl}/api/india/companies/searchCustmer`,
                {
                    email: formData.primaryEmail,
                    phone: formData.primaryPhone,
                }
            );
            console.log("Search API Response:", response?.data?.success);
            console.log("Company found ======================>>>>>>>>>>>>>", companyFound)

            if (response?.data?.success === false) {
                createAccountAPI();
            } else {
                // Handle cases where companyFound is false or response success is not true
                console.log("Email");
                console.log("Response of search API for email phone number", response)
                setErrorMessage(response?.data?.message);
                setButtonDisable(false);
            }
        } catch (error) {
            console.error("Error sending data to search API:", error);
            setErrorMessage('Failed to connect to the API');
            setButtonDisable(false);

        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoader(true);
        setButtonDisable(true);

        // Validate email
        // const validMail = EmailValidator.validate(formData.primaryEmail);
        // if (!validMail) {
        //     setErrorMessage("Invalid email address, Please fill valid email");
        //     setSubmitLoader(false);
        //     return;
        // }

        // Perform necessary data transformations
        if (formData.business === "sole-proprietorship" && formData.businessName === "") {
            formData.businessName = formData.ownerName;
            formData.ownerName = "";
        }

        if (companyFound === false || (haveGst === false && companyFound === null)) {
            await sendDataToSearchAPI();
        } else {
            setButtonDisable(false);
            return;
        }
        setSubmitLoader(false);
    };





    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isRedirect = queryParams.get('redirect') === 'true';

    return (
        <div className="india-container mx-auto px-4 py-6">
            <h1 className="text-center text-2xl  mt-4 mb-8">Create a Business Account</h1>
            {isRedirect && (
                <section className="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">

                    <p className="text-red-500">The email you entered is not registered or approved for access. Please check your email for accuracy,<br></br> or contact <span className="font-bold text-red-500">support@expobazaar.com</span>for assistance.</p>
                </section>
            )}

            <section className="border p-4 md:p-8 mb-6 rounded-lg india-businessInfo">
                <p className="mb-4">
                    As a Buyer, you'll enjoy exclusive pricing and access to a wide range of selected home and lifestyle brands. Our unique collection combines designs and patterns inspired by Indian artistry, offering products with global appeal.
                </p>
                <p className="mb-4">
                    Our dedicated sales team will work with you to curate home and lifestyle products that meet your specific sourcing requirements.
                </p>
                <p className="mb-0">
                    To speed up registration, please have the following information ready:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        Company and contact details
                    </li>
                    <li>
                        Billing and tax information
                    </li>
                </ul>
                <p className="mb-4">
                    For common questions, please visit our{" "}
                    <a href="https://expobazaarin.myshopify.com/pages/faq" className="underline">
                        <b>FAQ page.</b>
                    </a>{" "}
                    If you're an existing ExpoBazaar customer and need help, email us at{" "}
                    <a href="mailto:info@expobazaar.com" className="underline">
                        <b>info@expobazaar.com</b>
                    </a>
                    . For any other inquiries, please submit a request.
                </p>

            </section>

            <form className="space-y-6" onSubmit={handleSubmit}>
                <div className="space-y-6">
                    <section className="pb-6">
                        <h2 className="text-xl font-semibold mb-4">Company Details</h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">


                            <div className={`flex flex-col ${formData.business === "sole-proprietorship" ? 'mt-4' : 'mt-0'}`}>
                                <div className="flex items-center formLables">
                                    <label
                                        className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                        htmlFor="businessName"
                                    >
                                        Business Name*</label>

                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs focus:outline-[#00425B] "
                                        id="businessName"
                                        type="text"
                                        placeholder="Enter Business name" value={formData.businessName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="text-[10px] opacity-[50%] font-light pt-2 w-[65%] ml-[25%] block md:ml-[35%]">
                                    {isSoleProprietorship === true ? (<p>If different from owner's name</p>
                                    ) : (null)}
                                </div>
                            </div>
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="business"
                                >
                                    Business Type*
                                </label>
                                <select
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                    id="business"
                                    value={formData.business}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled hidden>
                                        Select Business Type
                                    </option>
                                    <option value="sole-proprietorship">Sole proprietorship</option>
                                    <option value="Partnership-Firm">Partnership Firm</option>
                                    <option value="Limited-Liability-Partnership-(LLP)">Limited Liability Partnership (LLP)</option>
                                    <option value="Private-Limited-Company-(Pvt. Ltd.)">Private Limited Company (Pvt. Ltd.)</option>
                                    <option value="Public-Limited-Company-(Ltd.)">Public Limited Company (Ltd.)</option>
                                    <option value="Corporation-(C-Corp)">Corporation (C-Corp)</option>
                                    <option value="S-Corporation-(S-Corp)">S Corporation (S-Corp)</option>
                                    <option value="Limited-Liability-Company(LLC)">Limited Liability Company (LLC)</option>
                                </select>
                            </div>

                            {formData.business === "sole-proprietorship" ? (
                                <div className="flex items-baseline formLables">
                                    <label
                                        className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                        htmlFor="ownerName"
                                    >
                                        Owner Name*
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs focus:outline-[#00425B]"
                                        id="ownerName"
                                        type="text"
                                        placeholder="Enter Owner name"
                                        value={formData.ownerName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            ) : (
                                <div className="flex flex-col">
                                    {/* content for sole proprietorship can go here if needed */}
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="pb-6">
                        <h2 className="text-xl font-semibold mb-4">Billing Address</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="addressline1"
                                >
                                    Address Line 1*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                    id="addressline1"
                                    type="text"
                                    placeholder="Enter Address Line 1"
                                    value={formData.addressline1}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="addressline2"
                                >
                                    Address Line 2
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs focus:outline-[#00425B]"
                                    id="addressline2"
                                    type="text"
                                    placeholder="Enter Address Line 2" value={formData.addressline2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="postalCode"
                                >
                                    Postal Code*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                    id="postalCode"
                                    type="text"
                                    placeholder="Enter Postal Code" value={formData.postalCode}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="city"
                                >
                                    City*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs focus:outline-[#00425B]"
                                    id="city"
                                    type="text"
                                    placeholder="Enter City" value={formData.city}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="state"
                                >
                                    <div className="">
                                        State*
                                    </div>
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs focus:outline-[#00425B]"
                                    id="state"
                                    type="text"
                                    placeholder="Enter State" value={formData.state}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="country"
                                >
                                    Country*
                                </label>
                                {/* <select
                                className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                id="country"
                                value={formData.country}
                                onChange={handleChange}
                                required
                            >
                                <option value="IN">India</option> */}

                                {/* <option value="AF">Afghanistan</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="AD">Andorra</option>
                                <option value="AO">Angola</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AU">Australia</option>
                                <option value="AT">Austria</option>
                                <option value="AZ">Azerbaijan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladesh</option>
                                <option value="BB">Barbados</option>
                                <option value="BY">Belarus</option>
                                <option value="BE">Belgium</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia</option>
                                <option value="BA">Bosnia and Herzegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BR">Brazil</option>
                                <option value="BN">Brunei</option>
                                <option value="BG">Bulgaria</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="KH">Cambodia</option>
                                <option value="CM">Cameroon</option>
                                <option value="CA">Canada</option>
                                <option value="CV">Cape Verde</option>
                                <option value="CF">Central African Republic</option>
                                <option value="TD">Chad</option>
                                <option value="CL">Chile</option>
                                <option value="CN">China</option>
                                <option value="CO">Colombia</option>
                                <option value="KM">Comoros</option>
                                <option value="CG">Congo (Brazzaville)</option>
                                <option value="CD">Congo (Kinshasa)</option>
                                <option value="CR">Costa Rica</option>
                                <option value="HR">Croatia</option>
                                <option value="CU">Cuba</option>
                                <option value="CY">Cyprus</option>
                                <option value="CZ">Czech Republic</option>
                                <option value="DK">Denmark</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="DO">Dominican Republic</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egypt</option>
                                <option value="SV">El Salvador</option>
                                <option value="GQ">Equatorial Guinea</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estonia</option>
                                <option value="ET">Ethiopia</option>
                                <option value="FJ">Fiji</option>
                                <option value="FI">Finland</option>
                                <option value="FR">France</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgia</option>
                                <option value="DE">Germany</option>
                                <option value="GH">Ghana</option>
                                <option value="GR">Greece</option>
                                <option value="GD">Grenada</option>
                                <option value="GT">Guatemala</option>
                                <option value="GN">Guinea</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HN">Honduras</option>
                                <option value="HU">Hungary</option>
                                <option value="IS">Iceland</option> */}
                                {/* <option value="IN">India</option> */}
                                {/* <option value="ID">Indonesia</option>
                                <option value="IR">Iran</option>
                                <option value="IQ">Iraq</option>
                                <option value="IE">Ireland</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italy</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japan</option>
                                <option value="JO">Jordan</option>
                                <option value="KZ">Kazakhstan</option>
                                <option value="KE">Kenya</option>
                                <option value="KI">Kiribati</option>
                                <option value="KP">North Korea</option>
                                <option value="KR">South Korea</option>
                                <option value="KW">Kuwait</option>
                                <option value="KG">Kyrgyzstan</option>
                                <option value="LA">Laos</option>
                                <option value="LV">Latvia</option>
                                <option value="LB">Lebanon</option>
                                <option value="LS">Lesotho</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libya</option>
                                <option value="LI">Liechtenstein</option>
                                <option value="LT">Lithuania</option>
                                <option value="LU">Luxembourg</option>
                                <option value="MG">Madagascar</option>
                                <option value="MW">Malawi</option>
                                <option value="MY">Malaysia</option>
                                <option value="MV">Maldives</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MR">Mauritania</option>
                                <option value="MU">Mauritius</option>
                                <option value="MX">Mexico</option>
                                <option value="FM">Micronesia</option>
                                <option value="MD">Moldova</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolia</option>
                                <option value="ME">Montenegro</option>
                                <option value="MA">Morocco</option>
                                <option value="MZ">Mozambique</option>
                                <option value="MM">Myanmar</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NP">Nepal</option>
                                <option value="NL">Netherlands</option>
                                <option value="NZ">New Zealand</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NO">Norway</option>
                                <option value="OM">Oman</option>
                                <option value="PK">Pakistan</option>
                                <option value="PW">Palau</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua New Guinea</option>
                                <option value="PY">Paraguay</option>
                                <option value="PE">Peru</option>
                                <option value="PH">Philippines</option>
                                <option value="PL">Poland</option>
                                <option value="PT">Portugal</option>
                                <option value="QA">Qatar</option>
                                <option value="RO">Romania</option>
                                <option value="RU">Russia</option>
                                <option value="RW">Rwanda</option>
                                <option value="KN">Saint Kitts and Nevis</option>
                                <option value="LC">Saint Lucia</option>
                                <option value="VC">Saint Vincent and the Grenadines</option>
                                <option value="WS">Samoa</option>
                                <option value="SM">San Marino</option>
                                <option value="ST">Sao Tome and Principe</option>
                                <option value="SA">Saudi Arabia</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia</option>
                                <option value="SC">Seychelles</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SK">Slovakia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SB">Solomon Islands</option>
                                <option value="SO">Somalia</option>
                                <option value="ZA">South Africa</option>
                                <option value="ES">Spain</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="SD">Sudan</option>
                                <option value="SR">Suriname</option>
                                <option value="SZ">Swaziland</option>
                                <option value="SE">Sweden</option>
                                <option value="CH">Switzerland</option>
                                <option value="SY">Syria</option>
                                <option value="TW">Taiwan</option>
                                <option value="TJ">Tajikistan</option>
                                <option value="TZ">Tanzania</option>
                                <option value="TH">Thailand</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="TG">Togo</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad and Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turkey</option>
                                <option value="TM">Turkmenistan</option>
                                <option value="TV">Tuvalu</option>
                                <option value="UG">Uganda</option>
                                <option value="UA">Ukraine</option>
                                <option value="AE">United Arab Emirates</option>
                                <option value="GB">United Kingdom</option>
                                <option value="US">United States</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="VE">Venezuela</option>
                                <option value="VN">Vietnam</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                                <option value="ZW">Zimbabwe</option> */}


                                {/* </select> */}

                                <span id="country" className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs inline-block">
                                    India
                                </span>

                                {/* Hidden input to submit the country value */}
                                <input type="hidden" name="country" value="IN" />

                            </div>

                        </div>
                    </section>

                    <section className="pb-6">
                        <h2 className="text-xl font-semibold mb-4">Primary Contact</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
                            <div className="flex items-center formLables">

                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="firstName"
                                >
                                    First Name*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs focus:outline-[#00425B]"
                                    id="firstName"
                                    type="text"
                                    placeholder="Enter First Name" value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex items-center formLables">
                                <label
                                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                    htmlFor="lastName"
                                >
                                    Last Name*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs focus:outline-[#00425B]"
                                    id="lastName"
                                    type="text"
                                    placeholder="Enter Last Name" value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex items-center flex-col ">
                                <div className="flex items-center flex-row w-[100%]">
                                    <label
                                        className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                                        htmlFor="primaryEmail"
                                    >
                                        Email Address*
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                        id="primaryEmail"
                                        type="email"
                                        placeholder="Enter Email Address" value={formData.primaryEmail}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex items-center flex-col">
                                <div className="flex items-center flex-row w-[100%]">
                                    <label
                                        className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                                        htmlFor="primaryPhone"
                                    >
                                        Mobile Phone No.*
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                        id="primaryPhone"
                                        type="text"
                                        placeholder="Enter Mobile Phone Number" value={formData.primaryPhone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex items-center flex-col">
                                <div className="flex items-center flex-row w-[100%]">
                                    <label
                                        className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                                        htmlFor="telephone"
                                    >
                                        Telephone No.
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                        id="telephone"
                                        type="text"
                                        placeholder="Enter Telephone Number" value={formData.telephone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="">
                        <h2 className="text-xl font-semibold mb-4">Tax Information</h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">

                            <div className="flex items-center flex-col">

                                <div className="flex items-center flex-row w-[100%]">
                                    <label
                                        className="flex text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                                        htmlFor="panNumber"
                                    >
                                        <div className="w-[65%] break-words">
                                            PAN Number*
                                        </div>

                                    </label>

                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                        id="panNumber"
                                        type="text"
                                        placeholder="Enter Unique PAN Number"
                                        value={formData.panNumber}
                                        onChange={handleChange}
                                        required
                                        maxLength={10}
                                    />
                                    <div className="p-2">
                                        {(formData?.panNumber?.length === 10) && (!panNumberLoader) && (
                                            panValid === false ? (
                                                <GoInfo className="text-red-500" size={20} />
                                            ) : (
                                                <CiCircleCheck className="text-green-500" size={20} />
                                            )
                                        )}
                                    </div>
                                </div>
                                {panUsername && <div><p className="text-xs text-green-500">{panUsername}</p></div>}


                            </div>
                            <div className="flex items-center flex-col">
                                <div className="flex items-center flex-row w-[100%]"></div>
                            </div>




                            {/* <div className="tax-radio-container flex justify-start  align-middle md:gap-28 flex-row">
                            <div className="text-sm font-medium">
                                Do you have GST?
                            </div>
                            <div className="flex flex-row">
                                <div
                                    className={`w-[20px] h-[20px] rounded-full ${haveGst === true ? 'border-[1px] bg-slate-300 ' : 'border-[5px]'} border-[#535454]`}

                                >              </div>
                                <div className="pl-5"><p>Yes</p></div>
                            </div>
                            <div className="flex flex-row justify-center align-middle">
                                <div
                                    className={`w-[20px] h-[20px] rounded-full ${haveGst === true ? 'border-[5px]' : 'border-[1px] bg-slate-300'} border-[#535454]`}

                                >               </div>
                                <div className="pl-5"><p>No</p></div>

                            </div>
                        </div> */}


                            <div className="tax-radio-container flex justify-start align-middle md:gap-10 flex-row">
                                <div className="text-sm font-medium">Do you have GST?</div>
                                <div className="flex gap-10 justify-start">
                                    <div className="flex flex-row items-center cursor-pointer" onClick={() => handleGstChange(true)}>
                                        <div
                                            className={`w-[20px] h-[20px] rounded-full ${haveGst === true ? ' border-[5px] ' : 'border-[1px]'} border-[#00425B]`}
                                        ></div>
                                        <div className="pl-5">
                                            <p>Yes</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-center items-center cursor-pointer" onClick={() => handleGstChange(false)}>
                                        <div
                                            className={`w-[20px] h-[20px] rounded-full ${haveGst === false ? 'border-[5px]' : 'border-[1px]'} border-[#00425B]`}
                                        ></div>
                                        <div className="pl-5">
                                            <p>No</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="flex items-center flex-col">
                                <div className="flex items-center flex-row w-[100%]"></div>
                            </div>


                            {haveGst === true ? <>
                                <div className="flex items-center flex-col">
                                    <div className="flex items-center flex-row w-[100%]">
                                        <label
                                            className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                                            htmlFor="gstNumber"
                                        >
                                            GSTIN Number{haveGst === true ? "*" : ""}
                                        </label>
                                        <div className="w-full md:w-2/3 flex">
                                            <input
                                                className=" w-full md:w-[70%]  border border-[#00425B] rounded px-3 py-2 text-xs focus:outline-[#00425B]"
                                                id="gstNumber"
                                                type="text"
                                                placeholder="Enter GSTIN or Business Tax Number" value={formData.gstNumber}
                                                onChange={handleChange}
                                                disabled={haveGst === false}
                                                required={haveGst === true}
                                            />


                                            <button
                                                className={`${(gstValid === null) || (gstValid === true && companyFound === true)
                                                    ? 'india-valid-subBtn'
                                                    : gstValid === false
                                                        ? 'bg-red-500 rounded-lg'
                                                        : gstValid === true && companyFound === false
                                                            ? 'bg-green-500 rounded-lg'
                                                            : ''} 
                                             md:w-[27%] ml-2 text-xs md:text-sm text-white py-[7px] px-1 flex justify-center items-center`}

                                                onClick={e => validateGstNumber(e)}
                                                disabled={!formData?.gstNumber || formData?.gstNumber?.length === 0}
                                            >
                                                {gstNumberLoader ? (
                                                    <div role="status" className="flex justify-center items-center">
                                                        <svg
                                                            aria-hidden="true"
                                                            className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                            viewBox="0 0 100 101"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                fill="currentFill"
                                                            />
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                ) : gstValid === null ? (
                                                    "Verify"
                                                ) : (
                                                    <div className={`font-bold`}>
                                                        {gstValid === true && companyFound === false ? (
                                                            <CiCircleCheck size={20} />
                                                        ) : (gstValid === false) || ((gstValid === true && companyFound === true)) ? (
                                                            <GoInfo size={20} />
                                                        ) : null}
                                                    </div>
                                                )}
                                            </button>



                                        </div>
                                    </div>
                                    {gstValidMessage && <div className="pl-10"><p className="text-xs text-green-500">{gstValidMessage}</p></div>}
                                    {gstValidErrorMessage && <div><p className="text-xs text-red-500">{gstValidErrorMessage}</p></div>}

                                </div>
                                <div className="flex items-center flex-col">
                                    <div className="flex items-center flex-row w-full">
                                        <label
                                            className="flex text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                                            htmlFor="gstDocument"
                                        >
                                            <div className="w-[70%] break-words">
                                                GSTIN Number Doc{haveGst === true ? "*" : ""}
                                            </div>
                                            <div className="pt-[2px] px-1">
                                                <img src={infoIcon} className="" id="gstNumberIcon" data-tooltip-id="gstNumberTooltip" data-tooltip-content={"Note: Upload your GST Proof in PDF format (up to 6MB) for easy verification."}
                                                />
                                            </div>
                                        </label>
                                        <Tooltip id="gstNumberTooltip" />

                                        <div className="relative w-full md:w-2/3">
                                            <input
                                                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer focus:outline-[#00425B]"
                                                id="gstDocument"
                                                type="file"
                                                accept=".pdf"
                                                onChange={handleChange}
                                                value={formData.gstDocument}
                                                required={haveGst === true}
                                            />
                                            <div className="flex border border-[#00425B] rounded px-3 py-2 items-center">
                                                <span className="flex-grow text-xs break-words w-[80%]">{formData.gstDocument ? formData.gstDocument : "Upload GSTIN or Business Tax Document"}</span>
                                                <label htmlFor="gstDocument" className="cursor-pointer">
                                                    {
                                                        pdfLoader === true ? (
                                                            <div role="status">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                    viewBox="0 0 100 101"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                        fill="currentColor"
                                                                    />
                                                                    <path
                                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                        fill="currentFill"
                                                                    />
                                                                </svg>
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        ) : pdfUploaded === true && pdfLoader === false ? (
                                                            <CiCircleCheck size={20} color="green" />
                                                        ) : (
                                                            <img src={uploadIcon} alt="Upload" className="ml-2" />
                                                        )
                                                    }

                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : null}


                            {/* <div className="flex items-center flex-col">
                            <div className="flex items-center flex-row w-[100%]">
                                <p className="text-[10px]">Note: If you're a regular taxpayer, upload your business details document in PDF format (up to 6MB) for verification or easy data recording.</p>
                            </div>
                        </div> */}
                        </div>
                    </section>

                    {gstCompanyFoundMessage && <section class="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">
                        <p className=" text-xs text-red-500">{gstCompanyFoundMessage}</p>
                    </section>
                    }
                    {companyFound && <section class="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">
                        <p className=" text-xs text-red-500">{companyFoundMessage}</p>
                    </section>
                    }

                    {/* <div className="india-container mx-auto px-4 py-3 relative">
                    {showThankyouPopup && (
                        <>
                            <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
                            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-96 pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center">
                                <button className="text-3xl md:text-4xl absolute right-4 top-4" onClick={e => handleCrossClicked(e)}>&times;</button>
                                <img src={europeLogo} alt="Expo Bazaar Logo" className="mx-auto mb-4 w-32 md:w-40" />
                                <div className='lastPageContainer pt-4 pb-4 md:pt-5 md:pb-5'>
                                    <h1 className="text-xl md:text-2xl font-semibold mb-2">Thank You</h1>
                                    <h1 className="text-xl md:text-2xl font-semibold mb-4">for Registering with us!</h1>
                                </div>
                                <button className="india-thankyou-subBtn px-8 py-2 text-white rounded-full text-sm" onClick={goToHomePage}>
                                    Get Started
                                </button>
                                <p className="india-thankyouQuestion mt-4 text-xs">
                                    <span className='font-bold'>Questions? Contact us:</span><span> info@expobazaar.com</span>
                                </p>
                            </div>
                        </>
                    )}
                </div> */}
                    <p className="text-red-600">{errorMessage}</p>

                    <div className="flex flex-col md:flex-row  justify-between items-center my-4">
                        <div>
                            <input
                                id="agree"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                className="hidden"
                            />
                            <label htmlFor="agree" className="custom-checkbox-label flex items-center">
                                <div className={`india-custom-checkbox ${isChecked ? 'checked' : ''}`}></div>
                                <span className="ml-2 text-gray-700 india-bazaarPolicy">
                                    By continuing, you agree to ExpoBazaar's{" "}
                                    <a href="https://expobazaarin.myshopify.com/pages/our-policy" className="underline">
                                        <b>Privacy Policy</b>
                                    </a>{" "}
                                    and{" "}
                                    <a href="https://expobazaarin.myshopify.com/policies/terms-of-service" className="underline">
                                        <b>Terms of Use</b>
                                    </a>
                                    .
                                </span>
                            </label>
                        </div>

                        <div className="flex justify-center md:justify-end pt-8 md:py-2">

                            {(submitLoader === true) ? (<button
                                type="button"
                                className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                            >
                                <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="#1C64F2"
                                    />
                                </svg>
                                Please Wait
                            </button>) : (<button
                                className={`${errorMessage !== "" || isChecked === false || (haveGst === true && gstValid !== true) || panValid != true || companyFound === true || buttonDisable === true ? "bg-white rounded-lg border-2 border-slate-200" : "india-subBtn text-white"}  py-2 px-12`}
                                disabled={errorMessage !== "" || isChecked === false || (haveGst === true && gstValid !== true) || panValid != true || companyFound === true || buttonDisable === true}
                            >
                                Submit
                            </button>)}
                        </div>

                    </div>

                    {/* <p className="text-red-600">{errorMessage}</p> */}

                </div>
            </form>
            <div className="india-container mx-auto px-4 py-3 relative">
                {showThankyouPopup && (
                    <>
                        <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-96 pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center">
                            <button className="text-3xl md:text-4xl absolute right-4 top-4" onClick={e => handleCrossClicked(e)}>&times;</button>
                            <img src={europeLogo} alt="Expo Bazaar Logo" className="mx-auto mb-4 w-32 md:w-40" />
                            <div className='lastPageContainer pt-4 pb-4 md:pt-5 md:pb-5'>
                                <h1 className="text-xl md:text-2xl font-semibold mb-2">Thank You</h1>
                                <h1 className="text-xl md:text-2xl font-semibold mb-4">for Registering with us!</h1>
                            </div>
                            <button className="india-thankyou-subBtn px-8 py-2 text-white rounded-full text-sm" onClick={goToHomePage}>
                                Get Started
                            </button>
                            <p className="india-thankyouQuestion mt-4 text-xs">
                                <span className='font-bold'>Questions? Contact us:</span><span> info@expobazaar.com</span>
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default IndiaRegistrationForm;