// import logo from "./logo.svg";
import "./App.css";
import RoutePage from "./routes";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style

function App() {
  return (
    <>
      <RoutePage />
    </>
  );
}

export default App;
