// import React from 'react';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// // Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// // Create Document Component
// export const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );






import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
  page: {
    padding: 30,
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  upperHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  middleHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainHeading: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 900,
  },
  leftHeaderContainerVerticalLine: {
    width: 1,
    height: 55,
    backgroundColor: 'black',
  },
  rightHeaderContainerVerticalLine: {
    width: 1,
    backgroundColor: 'black',
    height: 55,
  },
  headerRightContainer: {

  },
  headerContainerHorizontalLine: {
    width: '100%',
    height: 1,
    backgroundColor: 'black',
  },
  bottomHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  text: {
    fontSize: 8,
    padding: '1px',
    wordWrap: 'break-word',
  },
  highlightedText: {
    fontFamily: 'Open Sans',
    fontWeight: 900,
  },
  formdataText: {
    paddingLeft: 12,
  },
  largerText: {
    fontSize: 18,
    fontFamily: 'Open Sans',
    fontWeight: 900,

  },
  formContentContainer: {
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    borderLeft: '1px solid black',
    // borderBottom: '1px solid black',
  },
  firstPageRotatedTextContainer: {
    transform: 'rotate(-90deg)',
    fontSize: 8,
    fontWeight: 900,
    position: 'absolute',
    top: '250px',
    left: -20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContentFirstContainer: {
    borderBottom: '1px solid black',
    minHeight: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'

  },
  formContentFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  formContentFieldNumber: {
    fontSize: 8,
    fontWeight: 900,
    marginRight: '5px',
    marginLeft: '2.5px',
  },
  formContentSecondContainer: {
    borderBottom: '1px solid black',
    minHeight: 30,
  },
  formContentThirdContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid black',
  },
  formContentThirdLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',

  },
  formContentThirdLeftUpperContainer: {
    borderBottom: '1px solid black',

  },
  formContentThirdLeftUpperFirstContainer: {
    width: '90%',

  },
  formContentThirdLeftUpperSecondContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: 10,

  },
  formContentThirdLeftUpperThirdContainer: {
    width: '90%',

  },
  formContentThirdLeftUpperFourthContainer: {
    width: '90%',

  },
  formContentThirdLeftLowerContainer: {
    width: '90%',
  },
  formContentThirdRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    borderLeft: '1px solid black',
    justifyContent: 'space-evenly'
  },
  payeeCodeContainer: {
    paddingLeft: 10,
  },
  fatcaCodeContainer: {
    paddingLeft: 10,

  },
  formContentThirdRightContainerFieldContainerText: {
    width: '90%',
  },
  formContentThirdRightContainerBottomContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 7.5,
    width: '100%',
    textAlign: 'center'
  },
  underlineText: {
    textDecoration: 'underline',
  },
  formContentFourthContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid black',

  },
  formContentFourthLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
  },
  formContentFourthLeftUpperContainer: {
    borderBottom: '1px solid black',
    minHeight: 30,

  },
  formContentFourthLeftLowerContainer: {
    minHeight: 30,
  },
  formContentFourthRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    borderLeft: '1px solid black',
    paddingLeft: 10,
  },
  formContentFifthContainer: {
    minHeight: 30,

  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    paddingLeft: 5,
  },
  checkbox: {
    width: 7.5,
    height: 7.5,
    borderWidth: 1,
    borderColor: '#000',
    marginRight: 5,
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
  },
  firstPartContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  firstPartHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

  },
  firstPartHeadingLeftContainer: {
    backgroundColor: 'black',
    color: 'white',
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstPartHeadingRightContainer: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    width: '90%',

  },
  firstPartContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  firstPartContentLeftContainer: {
    width: '67.5%',
  },
  firstPartContentLeftContainerFirstText: {
    paddingTop: 2,
    paddingBottom: 5,
  },
  firstPartContentRightContainer: {
    width: '27.5%',
  },
  firstPartHeadingRightContainerHeading: {
    paddingLeft: 12,
  },
  firstPartContentRightUpperContainerText: {
    borderLeft: '1px solid black',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    paddingLeft: 5,
  },
  firstPartContentRightMiddleContainer: {

  },
  firstPartContentRightLowerContainerText: {
    border: '1px solid black',
    paddingLeft: 5,
  },

  boxContainer: {
    flexDirection: 'row',
    marginTop: 3,

  },
  box: {
    width: 15,
    height: 15,
    borderWidth: 1,
    borderColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 2,
  },
  boxText: {
    fontSize: 10,
  },
  signImageContainer: {
    width: '40px',
    height: '20px',
    position: 'absolute',
    bottom: '25%',
    left: '25%',
  },
  firstPageDateContainer: {
    position: 'absolute',
    bottom: '25.1%',
    right: '29%',
  },
  signImage: {
    width: '40px',
    height: '20px',
    position: 'relative',
  },
  secondPartContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 2,
  },
  secondPartHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

  },
  secondPartHeadingLeftContainer: {
    backgroundColor: 'black',
    color: 'white',
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  secondPartHeadingRightContainer: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    width: '90%',

  },
  secondPartHeadingRightContainerHeading: {
    paddingLeft: 12,
  },
  firstPageImageContainer: {
    width: '100%',
    // height: '45%',
    position: 'absolute',
    bottom: 5,
    left: 30,
  },
  firstPageImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'relative',
    top: '0%',
    left: '0%',
  },
  ImageContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 30,
    left: 30,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'relative',

  },

});

const businessTypes = [
  "sole-proprietorship",
  "c-corporation",
  "s-corporation",
  "partnership",
  "trust/estate",
  "llc",
];

const Checkbox = ({ isChecked }) => (
  <View style={styles.checkbox}>
    {isChecked && <Image src="/images/right.png" style={{ width: 5, height: 5 }} />}
  </View>
);

const BoxField = ({ value = '' }) => {
  const boxes = Array(9).fill('');

  for (let i = 0; i < value.length && i < 9; i++) {
    boxes[i] = value[i];
  }

  return (
    <View style={styles.boxContainer}>
      {boxes.map((char, index) => (
        <View key={index} style={styles.box}>
          <Text style={styles.boxText}>{char}</Text>
        </View>
      ))}
    </View>
  );
};
const currentDate = new Date().toLocaleDateString();

export const MyDocument = ({ formData, signURL }) => (

  <Document>
    <Page size="A4" style={styles.page}>

      <View style={styles.firstPageImageContainer}>
        <Image style={styles.firstPageImage} src="/images/pdfFirstPageImage.jpg" />
      </View>
      <View style={styles.firstPageDateContainer}>
        <Text style={styles.text}>{currentDate}</Text>
      </View>
      <View style={styles.signImageContainer}>
        {signURL && <Image style={styles.signImage} src={signURL} />}
      </View>

      <View style={styles.firstPageRotatedTextContainer}>
        <Text style={styles.text}>Print or type.</Text>
        <Text style={styles.text}>See Specific Instructions on page 3</Text>
      </View>

      <View style={styles.headerContainer}>
        <View style={styles.upperHeaderContainer}>
          <View>
            <Text style={styles.text}>
              From <Text style={styles.largerText}> W-9</Text>
            </Text>
            <Text style={styles.text}>(Rev. March 2024)</Text>
            <Text style={styles.text}>Department of the Treasury</Text>
            <Text style={styles.text}>Internal Revenue Service</Text>
          </View>
          <View style={styles.leftHeaderContainerVerticalLine} />
          <View style={styles.middleHeaderContainer}>
            <Text style={styles.mainHeading}>Request for Taxpayer</Text>
            <Text style={styles.mainHeading}>Identification Number and Certification</Text>
            <Text style={[styles.text, styles.highlightedText]}>Go to www.irs.gov/FormW9 for instructions and the latest information</Text>
          </View>
          <View style={styles.rightHeaderContainerVerticalLine} />
          <View style={styles.headerRightContainer}>
            <Text style={[styles.text, styles.headerRightContainerText]}>
              Give form to the{'\n'}
              requester. Do not{'\n'}
              send to the IRS.
            </Text>
          </View>
        </View>
        <View style={styles.headerContainerHorizontalLine} />
        <View style={styles.bottomHeaderContainer}>
          <View>
            <Text style={[styles.text, styles.highlightedText]}>Before you begin.</Text>
          </View>
          <View>
            <Text style={styles.text}>For guidance related to the purpose of Form W-9, see Purpose of Form, below.</Text>
          </View>
        </View>
        <View style={styles.headerContainerHorizontalLine} />
      </View>

      <View style={styles.formContentContainer}>
        <View style={styles.formContentFirstContainer}>
          <View style={styles.formContentFieldContainer}>
            <Text style={styles.formContentFieldNumber}>1</Text>
            <Text style={styles.text}>
              Name of entity/individual. An entry is required. (For a sole proprietor or disregarded entity, enter the owner’s name on line 1, and enter the business/disregarded entity's name on line 2.)
            </Text>
          </View>
          {/* <View>
            <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>{`${formData?.ownerName}`}</Text>
          </View> */}
          <View>
            {formData?.business === "sole-proprietorship" ? (
              <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>
                {formData.ownerName}
              </Text>
            ) : (
              <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>
                {formData?.businessName}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.formContentSecondContainer}>
          <View style={styles.formContentFieldContainer}>
            <Text style={[styles.formContentFieldNumber, styles.highlightedText]}>2</Text>
            <Text style={styles.text}>Business name/disregarded entity name, if different from above.</Text>
          </View>
          {/* <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>{formData?.companyName}</Text> */}
          <View>
            {formData?.business === "sole-proprietorship" ? (
              <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>
                {formData?.businessName}
              </Text>
            ) : (
              null
            )}
          </View>
        </View>
        <View style={styles.formContentThirdContainer}>
          <View style={styles.formContentThirdLeftContainer}>
            <View style={styles.formContentThirdLeftUpperContainer}>
              <View style={[styles.formContentFieldContainer, styles.formContentThirdLeftUpperFirstContainer]}>
                <Text style={[styles.formContentFieldNumber, styles.highlightedText]}>3a</Text>
                <Text style={styles.text}>Check the appropriate box for federal tax classification of the entity/individual whose name is entered on line 1. Check
                  only one of the following seven boxes.
                </Text>
              </View>
              <View style={styles.formContentThirdLeftUpperSecondContainer}>
                {businessTypes.map((type) => (
                  <View key={type} style={styles.checkboxContainer}>
                    <Checkbox isChecked={formData?.business === type} />
                    <Text style={styles.text}>{type}</Text>
                  </View>
                ))}
              </View>
              <View style={styles.formContentThirdLeftUpperThirdContainer}>
                <View style={styles.checkboxContainer}>
                  <Checkbox />
                  <Text style={styles.text}>LLC. Enter the tax classification (C = C corporation, S = S corporation, P = Partnership) . . . .
                    Note: Check the “LLC” box above and, in the entry space, enter the appropriate code (C, S, or P) for the tax
                    classification of the LLC, unless it is a disregarded entity. A disregarded entity should instead check the appropriate
                    box for the tax classification of its owner.</Text>
                </View>
              </View>
              <View style={styles.formContentThirdLeftUpperFourthContainer}>
                <View style={styles.checkboxContainer}>
                  <Checkbox />
                  <Text style={styles.text}>Other (see instructions): ____________________ </Text>
                </View>
              </View>


            </View>
            <View style={styles.formContentThirdLeftLowerContainer}>
              <View style={styles.formContentFieldContainer}>
                <Text style={[styles.formContentFieldNumber, styles.highlightedText]}>3b</Text>
                <Text style={styles.text}>
                  If on line 3a you checked “Partnership” or “Trust/estate,” or checked “LLC” and entered “P” as its tax classification, and you are providing this form to a partnership, trust, or estate in which you have an ownership interest, check this box if you have any foreign partners, owners, or beneficiaries. See instructions . . . . . . . . .
                </Text>

              </View>
            </View>
          </View>
          <View style={styles.formContentThirdRightContainer}>
            <View style={styles.formContentFieldContainer}>
              <Text style={[styles.formContentFieldNumber, styles.highlightedText]}>4</Text>
              <Text style={[styles.text, styles.formContentThirdRightContainerFieldContainerText]}>
                Exemptions (codes apply only to certain entities, not individuals; see instructions on page 3):
              </Text>
            </View>
            <View style={styles.payeeCodeContainer}>
              <Text style={styles.text}>Exempt payee code (if any) _______</Text>
              <Text style={[styles.text, styles.underlineText, styles.formdataText]}>{formData?.payeeCode}</Text>
            </View>
            <View style={styles.fatcaCodeContainer}>
              <Text style={styles.text}>Exemption from Foreign Account Tax Compliance Act (FATCA) reporting code (if any)</Text>
              <Text style={[styles.text, styles.underlineText, styles.formdataText]}>{formData?.fatcaCode}</Text>
            </View>
            <View style={styles.formContentThirdRightContainerBottomContainer}>
              <Text style={styles.text}>(Applies to accounts maintained outside the United States.)</Text>
            </View>
          </View>
        </View>
        <View style={styles.formContentFourthContainer}>
          <View style={styles.formContentFourthLeftContainer}>
            <View style={styles.formContentFourthLeftUpperContainer}>
              <View style={styles.formContentFieldContainer}>
                <Text style={[styles.formContentFieldNumber, styles.highlightedText]}>5</Text>
                <Text style={styles.text}>Address (number, street, and apt. or suite no.). See instructions.</Text>
              </View>
              <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>{formData?.street1 + '  ' + formData?.street2}</Text>
            </View>
            <View style={styles.formContentFourthLeftLowerContainer}>
              <View style={styles.formContentFieldContainer}>
                <Text style={[styles.formContentFieldNumber, styles.highlightedText]}>6</Text>
                <Text style={styles.text}>City, state, and ZIP code.</Text>
              </View>
              <View>
                <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>{formData?.city + ', ' + formData?.state + ', ' + formData?.zipCode}</Text>
              </View>
            </View>
          </View>
          <View style={styles.formContentFourthRightContainer}>
            <View style={styles.formContentFieldContainer}>
              <Text style={styles.text}>Requester's name and address (optional)</Text>
            </View>
            <Text style={[styles.text, styles.highlightedText, styles.formdataText]}>{formData?.requesterName}</Text>
          </View>
        </View>
        <View style={styles.formContentFifthContainer}>
          <View style={styles.formContentFieldContainer}>
            <Text style={[styles.formContentFieldNumber, styles.highlightedText]}>7</Text>
            <Text style={styles.text}>List account number(s) here (optional)</Text>
          </View>
          <Text style={[styles.text, styles.underlineText, styles.highlightedText, styles.formdataText]}>{formData?.accountNumber}</Text>
        </View>
      </View>
      <View style={styles.firstPartContainer}>
        <View style={styles.firstPartHeadingContainer}>
          <View style={styles.firstPartHeadingLeftContainer}>
            <Text style={[styles.text, styles.highlightedText]}>Part I</Text>
          </View>
          <View style={styles.firstPartHeadingRightContainer}>
            <Text style={[styles.text, styles.highlightedText, styles.firstPartHeadingRightContainerHeading]}>Taxpayer Identification Number (TIN)</Text>
          </View>

        </View>
        <View style={styles.firstPartContentContainer}>
          <View style={styles.firstPartContentLeftContainer}>
            <Text style={[styles.text, styles.firstPartContentLeftContainerFirstText]}>
              Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid
              backup withholding. For individuals, this is generally your social security number (SSN). However, for a
              resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other
              entities, it is your employer identification number (EIN). If you do not have a number, see How to get a
              TIN, later.
            </Text>
            <Text style={styles.text}>
              Note: If the account is in more than one name, see the instructions for line 1. See also What Name and
              Number To Give the Requester for guidelines on whose number to enter.
            </Text>
          </View>
          <View style={styles.firstPartContentRightContainer}>
            <View style={styles.firstPartContentRightUpperContainer}>
              <Text style={[styles.text, styles.highlightedText, styles.firstPartContentRightUpperContainerText]}>Social security number</Text>
              <BoxField value={formData?.ssn} />

            </View>
            <View style={styles.firstPartContentRightMiddleContainer}>
              <Text style={[styles.text, styles.highlightedText]}>Or</Text>
            </View>
            <View style={styles.firstPartContentRightLowerContainer}>
              <Text style={[styles.text, styles.highlightedText, styles.firstPartContentRightLowerContainerText]}>Employer identification number</Text>
              <BoxField value={formData?.taxId} />
            </View>
          </View>
        </View>
      </View>
      <View style={styles.secondPartContainer}>
        <View style={styles.secondPartHeadingContainer}>
          <View style={styles.secondPartHeadingLeftContainer}>
            <Text style={[styles.text, styles.highlightedText]}>Part II</Text>
          </View>
          <View style={styles.secondPartHeadingRightContainer}>
            <Text style={[styles.text, styles.highlightedText, styles.secondPartHeadingRightContainerHeading]}>Certification</Text>
          </View>
        </View>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.ImageContainer}>
        <Image style={styles.image} src="/images/pdfSecondPageImage.jpg" />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.ImageContainer}>
        <Image style={styles.image} src="/images/pdfThirdPageImage.jpg" />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.ImageContainer}>
        <Image style={styles.image} src="/images/pdfFourthPageImage.jpg" />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.ImageContainer}>
        <Image style={styles.image} src="/images/pdfFifthPageImage.jpg" />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.ImageContainer}>
        <Image style={styles.image} src="/images/pdfSixthPageImage.jpg" />
      </View>
    </Page>
  </Document>
);