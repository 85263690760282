// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { useEffect } from "react";
// import { parsePhoneNumber } from 'awesome-phonenumber';
// import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
// import * as EmailValidator from 'email-validator';

// function RegistrationForm() {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     ownerName: "",
//     business: "",
//     otherBusinessType: "",
//     street1: "",
//     street2: "",
//     zipCode: "",
//     city: "",
//     state: "",
//     country: "US",
//     phone: "",
//     addressType: "",
//     dockDoor: "no",
//     firstName: "",
//     lastName: "",
//     primaryPhone: "",
//     primaryEmail: "",
//     // password: "",
//     // confirmPassword: "",
//     taxId: "",
//     ssn: "",
//   });
//   console.log(formData);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [buttonClicked, setButtonClicked] = useState(false)
//   // const [einClicked, setEinClicked] = useState(true)
//   // const [ssnClicked, setSsnClicked] = useState(false)
//   const [isChecked, setIsChecked] = useState(false);
//   const [issoleproprietorship, setIssoleproprietorship] = useState(false)


//   useEffect(() => {
//     const storedFormData = JSON.parse(localStorage.getItem("formData"));
//     if (storedFormData) {
//       setFormData(storedFormData);
//     }
//   }, []);
//   useEffect(() => {
//     if (errorMessage !== "") {
//       setButtonClicked(false);
//     }
//   }, [errorMessage]);

//   const handleChange = (e) => {
//     if (formData.business === "sole-proprietorship") {
//       setIssoleproprietorship(true);
//     } else {
//       setIssoleproprietorship(false);
//     }
//     const { id, value } = e.target;
//     if (id === "primaryPhone" || id === "phone") {
//       // console.log("phone",value)
//       let phoneNumber = value.replace(/\D/g, "").slice(0, 10)
//       setFormData({ ...formData, [id]: phoneNumber });

//     } else if (id === "zipCode") {
//       let zipCode = value.replace(/\D/g, "").slice(0, 5)
//       setFormData({ ...formData, [id]: zipCode });
//     } else {
//       setFormData({ ...formData, [id]: value });
//     }
//     setErrorMessage("");

//   };
//   // const handleEinClicked = async (e) => {
//   //   setEinClicked(true);
//   //   setSsnClicked(false);
//   // }
//   // const handleSsnClicked = async (e) => {
//   //   setSsnClicked(true);
//   //   setEinClicked(false);
//   // }

//   const sendDataToSearchAPI = async () => {
//     try {
//       const response = await axios.post(
//         "https://expobazaar.onrender.com/companies/search",
//         {
//           email: formData.primaryEmail,
//           phone: formData.primaryPhone,
//         }
//       );
//       console.log("Search API Response:", response?.data?.success);
//       if (response?.data?.success === true) {
//         navigate("/confirmation", { state: { formData } });
//       } else {
//         setErrorMessage(response?.data?.message);
//       }
//     } catch (error) {
//       console.error("Error sending data to search API:", error);
//     }
//   };

//   const handleCheckboxChange = (e) => {
//     setIsChecked(!isChecked);

//     if (e.target.checked) {
//       setFormData({ ...formData, primaryPhone: formData.phone });
//     } else {
//       setFormData({ ...formData, primaryPhone: "" });
//     }
//   };

//   const handleSubmit = async (e) => {
//     setButtonClicked(true);
//     e.preventDefault();
//     console.log("formdata", formData);
//     // if (formData.password !== formData.confirmPassword) {
//     //   setErrorMessage("Please fill same password");
//     //   return;
//     // }

//     if (formData.otherBusinessType != "" && formData.business === "sole-proprietorship") {
//       formData.business = formData.otherBusinessType;
//     }

//     const pn = parsePhoneNumber(formData.primaryPhone, { regionCode: 'US' });
//     if (pn.valid === false) {
//       setErrorMessage("Invalid phone number, Please fill valid US number");
//       return;
//     }
//     const validMail = EmailValidator.validate(formData.primaryEmail);
//     console.log(validMail);
//     if (validMail === false) {
//       setErrorMessage("Invalid email address, Please fill valid email");
//       return;
//     }

//     const zipValid = postcodeValidator(formData.zipCode, 'US');
//     if (zipValid === false) {
//       setErrorMessage("Invalid Zipcode, Please fill valid US Zipcode");
//       return;
//     }
//     console.log("Zip code authentication=====>>>>>", zipValid);



//     sendDataToSearchAPI();

//   };

//   return (
//     <div className="container mx-auto px-4 py-6">
//       <h1 className="text-center text-2xl  mt-4 mb-8">Create a Business Account</h1>
//       <section className="border p-4 md:p-8 mb-6 rounded-lg businessInfo">
//         <p className="mb-4">
//           As a Buyer, you'll enjoy exclusive pricing and access to a wide range of selected home and lifestyle brands. Our unique collection combines designs and patterns inspired by Indian artistry, offering products with global appeal.
//         </p>
//         <p className="mb-4">
//           Our dedicated sales team will work with you to curate home and lifestyle products that meet your specific sourcing requirements.
//         </p>
//         <p className="mb-0">
//           To speed up registration, please have the following information ready:
//         </p>
//         <ul className="list-disc list-inside mb-4">
//           <li>
//             <b>Company and contact details</b>

//           </li>
//           <li>
//             <b>Billing and tax information</b>
//           </li>
//         </ul>
//         <p className="mb-4">
//           For common questions, please visit our{" "}
//           <a href="https://expobazaarus.myshopify.com/pages/faq" className="underline">
//             <b>FAQ page.</b>
//           </a>{" "}
//           If you're an existing ExpoBazaar customer and need help, email us at{" "}
//           <a href="mailto:info@expobazaar.com" className="underline">
//             <b>info@expobazaar.com</b>
//           </a>
//           . For any other inquiries, please submit a request.
//         </p>
//         <p className="">
//           <b>Important: </b> To receive tax exemption, you'll need to digitally sign a W9 form during registration process.
//         </p>
//       </section>

//       <form className="space-y-6" onSubmit={handleSubmit}>
//         <section className="pb-6">
//           <h2 className="text-xl font-semibold mb-4">Company Details</h2>
//           {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
//             <div className="flex items-center formLables ">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="ownerName"
//               >
//                 Company Name*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2"
//                 id="ownerName"
//                 type="text"
//                 placeholder="Enter Company Name"
//                 value={formData.ownerName}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="businessType"
//               >
//                 Business Type*
//               </label>
//               <select
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2"
//                 id="businessType"
//                 value={formData.businessType}
//                 onChange={handleChange}
//                 required
//               >
//                 <option value="" disabled hidden>
//                   Select business type
//                 </option>
//                 <option value="sole-proprietorship">Sole proprietorship</option>
//                 <option value="c-corporation">C Corporation</option>
//                 <option value="s-corporation">S Corporation</option>
//                 <option value="partnership">Partnership</option>
//                 <option value="trust/estate">Trust/Estate</option>
//                 <option value="llc">LLC</option>
//                 <option value="other">Other</option>
//               </select>
//             </div>
//             {formData.businessType === "other" ? (
//               <div className="flex items-center formLables">
//                 <label
//                   className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                   htmlFor="otherBusinessType"
//                 >
//                   Other Business Type
//                 </label>
//                 <input
//                   className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2"
//                   id="otherBusinessType"
//                   type="text"
//                   placeholder="Enter Other Business Type"
//                   value={formData.otherBusinessType}
//                   onChange={handleChange}
//                 />
//               </div>
//             ) : null}
//           </div> */}
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">

//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="business"
//               >
//                 Business Type*
//               </label>
//               <select
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                 id="business"
//                 value={formData.business}
//                 onChange={handleChange}
//                 required
//               >
//                 <option value="" disabled hidden>
//                   Select business type
//                 </option>
//                 <option value="sole-proprietorship">Sole proprietorship</option>
//                 <option value="c-corporation">C Corporation</option>
//                 <option value="s-corporation">S Corporation</option>
//                 <option value="partnership">Partnership</option>
//                 <option value="trust/estate">Trust/Estate</option>
//                 <option value="llc">LLC</option>
//                 {/* <option value="other">Other</option> */}
//               </select>
//             </div>


//             <div className="flex flex-col mt-3">
//               <div className="flex items-center formLables">
//                 <label
//                   className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                   htmlFor="ownerName"
//                 >
//                   Business Name*
//                 </label>
//                 <input
//                   className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
//                   id="ownerName"
//                   type="text"
//                   placeholder="Enter Business Name"
//                   value={formData.ownerName}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="text-xs pt-2 w-[65%] ml-[15%] block md:ml-[35%]">
//                 <p>Note :- Please fill only if Business name is different</p>
//               </div>
//             </div>
//             {formData.business === "sole-proprietorship" && (
//               <div className="flex items-center formLables">
//                 <label
//                   className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                   htmlFor="otherBusinessType"
//                 >
//                   Owner Name*
//                 </label>
//                 <input
//                   className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
//                   id="otherBusinessType"
//                   type="text"
//                   placeholder="Enter Owner Name"
//                   value={formData.otherBusinessType}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//             )}

//           </div>


//         </section>

//         <section className="pb-6">
//           <h2 className="text-xl font-semibold mb-4">Billing Address</h2>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="street1"
//               >
//                 Street 1*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs "
//                 id="street1"
//                 type="text"
//                 placeholder="Enter Street 1"
//                 value={formData.street1}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="street2"
//               >
//                 Street 2
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
//                 id="street2"
//                 type="text"
//                 placeholder="Enter Street 2"
//                 value={formData.street2}
//                 onChange={handleChange}
//               />
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="zipCode"
//               >
//                 Zip Code*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                 id="zipCode"
//                 type="text"
//                 placeholder="Enter Zip Code"
//                 value={formData.zipCode}
//                 onChange={handleChange}
//                 required
//               // maxLength={5}
//               />
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="city"
//               >
//                 City*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
//                 id="city"
//                 type="text"
//                 placeholder="Enter City"
//                 value={formData.city}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="state"
//               >
//                 State*
//               </label>
//               <select
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                 id="state"
//                 value={formData.state}
//                 onChange={handleChange}
//                 required
//               >
//                 <option>Select State</option>
//                 <option value="AL">Alabama</option>
//                 <option value="AK">Alaska</option>
//                 <option value="AZ">Arizona</option>
//                 <option value="AR">Arkansas</option>
//                 <option value="CA">California</option>
//                 <option value="CO">Colorado</option>
//                 <option value="CT">Connecticut</option>
//                 <option value="DE">Delaware</option>
//                 <option value="FL">Florida</option>
//                 <option value="GA">Georgia</option>
//                 <option value="HI">Hawaii</option>
//                 <option value="ID">Idaho</option>
//                 <option value="IL">Illinois</option>
//                 <option value="IN">Indiana</option>
//                 <option value="IA">Iowa</option>
//                 <option value="KS">Kansas</option>
//                 <option value="KY">Kentucky</option>
//                 <option value="LA">Louisiana</option>
//                 <option value="ME">Maine</option>
//                 <option value="MD">Maryland</option>
//                 <option value="MA">Massachusetts</option>
//                 <option value="MI">Michigan</option>
//                 <option value="MN">Minnesota</option>
//                 <option value="MS">Mississippi</option>
//                 <option value="MO">Missouri</option>
//                 <option value="MT">Montana</option>
//                 <option value="NE">Nebraska</option>
//                 <option value="NV">Nevada</option>
//                 <option value="NH">New Hampshire</option>
//                 <option value="NJ">New Jersey</option>
//                 <option value="NM">New Mexico</option>
//                 <option value="NY">New York</option>
//                 <option value="NC">North Carolina</option>
//                 <option value="ND">North Dakota</option>
//                 <option value="OH">Ohio</option>
//                 <option value="OK">Oklahoma</option>
//                 <option value="OR">Oregon</option>
//                 <option value="PA">Pennsylvania</option>
//                 <option value="RI">Rhode Island</option>
//                 <option value="SC">South Carolina</option>
//                 <option value="SD">South Dakota</option>
//                 <option value="TN">Tennessee</option>
//                 <option value="TX">Texas</option>
//                 <option value="UT">Utah</option>
//                 <option value="VT">Vermont</option>
//                 <option value="VA">Virginia</option>
//                 <option value="WA">Washington</option>
//                 <option value="WV">West Virginia</option>
//                 <option value="WI">Wisconsin</option>
//                 <option value="WY">Wyoming</option>
//               </select>
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="country"
//               >
//                 Country*
//               </label>

//               <input
//                 className="w-full bg-slate-200 md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
//                 id="phone"
//                 type="text"
//                 readOnly
//                 value="United States"
//               />

//               {/* <select
//                 className="w-full md:w-2/3 border border-gray-300 rounded px-3 py-2"
//                 id="country"
//                 value={formData.country}
//                 onChange={handleChange}
//               >
//                 <option>Select Country</option>
//                 <option value="US">USA</option>
//               </select> */}
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="phone"
//               >
//                 Phone*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
//                 id="phone"
//                 type="text"
//                 placeholder="Enter Phone Number"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             {/* <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="addressType"
//               >
//                 Address Type*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-gray-300 rounded px-3 py-2"
//                 id="addressType"
//                 type="text"
//                 value={formData.addressType}
//                 onChange={handleChange}
//                 required
//               />
//             </div> */}
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="dockDoor"
//               >
//                 Dock Door
//               </label>
//               <select
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                 id="dockDoor"
//                 value={formData.dockDoor}
//                 onChange={handleChange}
//               >
//                 <option value="no">No</option>
//                 <option value="yes">Yes</option>
//               </select>
//             </div>
//           </div>
//         </section>

//         <section className="pb-6">
//           <h2 className="text-xl font-semibold mb-4">Primary Contact</h2>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="firstName"
//               >
//                 First Name*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                 id="firstName"
//                 type="text"
//                 placeholder="Enter First Name"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="lastName"
//               >
//                 Last Name*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
//                 id="lastName"
//                 type="text"
//                 placeholder="Enter Last Name"
//                 value={formData.lastName}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="flex items-center flex-col">
//               <div className="flex items-center flex-row w-[100%]">
//                 <label
//                   className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
//                   htmlFor="primaryPhone"
//                 >
//                   Phone*
//                 </label>
//                 <input
//                   className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                   id="primaryPhone"
//                   type="text"
//                   placeholder="Enter Phone Number"
//                   value={formData.primaryPhone}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//               {/* <div className="text-xs pt-0 w-[65%] ml-[35%]">
//                 <div className="flex items-center my-4">
//                   <input
//                     id="agree"
//                     type="checkbox"
//                     className="form-checkbox h-3 w-3 text-blue-600"
//                     onChange={handleCheckboxChange}
//                   />
//                   <label htmlFor="agree" className="ml-2 text-gray-700 bazaarPolicy">
//                     <p>Same as primary contact</p>
//                   </label>
//                 </div>
//               </div> */}
//               {/* <div className="text-xs pt-0 w-[65%] ml-[15%] md:ml-[35%]">
//                 <div className="flex items-center my-4">
//                   <input
//                     id="agree"
//                     type="checkbox"
//                     checked={isChecked}
//                     onChange={handleCheckboxChange}
//                     className="hidden"
//                   />
//                   <label htmlFor="agree" className="custom-checkbox-label flex items-center">
//                     <div className={`custom-checkbox ${isChecked ? 'checked' : ''}`}></div>
//                     <span className="ml-2 text-gray-700 bazaarPolicy">
//                       Same as primary contact
//                     </span>
//                   </label>
//                 </div>
//               </div> */}
//             </div>

//             <div className="flex items-center flex-col ">
//               <div className="flex items-center flex-row w-[100%]">
//                 <label
//                   className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
//                   htmlFor="primaryEmail"
//                 >
//                   Email*
//                 </label>
//                 <input
//                   className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                   id="primaryEmail"
//                   type="email"
//                   placeholder="Enter Email"
//                   value={formData.primaryEmail}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               {/* <div className="text-xs pt-2 w-[65%] ml-[15%] block md:ml-[35%]">
//                 <p>An automated email will be sent to this email with your login credentials</p>
//               </div> */}

//             </div>


//             {/* <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="password"
//               >
//                 Password*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-gray-300 rounded px-3 py-2"
//                 id="password"
//                 type="password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="flex items-center formLables">
//               <label
//                 className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                 htmlFor="confirmPassword"
//               >
//                 Confirm Password*
//               </label>
//               <input
//                 className="w-full md:w-2/3 border border-gray-300 rounded px-3 py-2"
//                 id="confirmPassword"
//                 type="password"
//                 value={formData.confirmPassword}
//                 onChange={handleChange}
//                 required
//               />
//             </div> */}
//           </div>
//         </section>

//         <section className="">
//           <div className="tax-radio-container flex justify-start  align-middle md:gap-28 flex-row">
//             <div>
//               <h2 className="text-xl font-semibold mb-4">Tax Information</h2>
//             </div>
//             <div className="flex flex-row">
//               <div
//                 className={`w-[20px] h-[20px] rounded-full ${ true ? 'border-[5px]' : 'border-[1px]'} border-[#535454]`}

//               >              </div>
//               <div className="pl-5"><p>Federal Tax ID/EIN No.</p></div>

//             </div>
//             <div className="flex flex-row justify-center align-middle">
//               <div
//                 className={`w-[20px] h-[20px] rounded-full ${true ? 'border-[5px]' : 'border-[1px]'} border-[#535454]`}

//               >               </div>
//               <div className="pl-5"><p>SSN No.</p></div>

//             </div>
//           </div>
//           <div className="border rounded-lg p-4 md:p-6">
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//               {/* {einClicked === true ? (<div className="flex items-center formLables"> */}
//               {(issoleproprietorship === true) ? (
//                 <div className="flex items-center formLables">
//                   <label
//                     className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                     htmlFor="ssn"
//                   >
//                     SSN No.*
//                   </label>
//                   <input
//                     className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                     id="ssn"
//                     type="text"
//                     placeholder="Enter SSN No."
//                     value={formData.ssn}
//                     onChange={handleChange}
//                     required
//                     maxLength={9}
//                   />
//                 </div>
//               ) : (


//                 <div className="flex items-center formLables">
//                   <label
//                     className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
//                     htmlFor="taxId"
//                   >
//                     Federal Tax ID/EIN No.*
//                   </label>
//                   <input
//                     className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
//                     id="taxId"
//                     type="text"
//                     placeholder="Enter Federal Tax ID/EIN No."
//                     value={formData.taxId}
//                     onChange={handleChange}
//                     required
//                     maxLength={9}

//                   />
//                 </div>
//               )}


//             </div>
//             {/* <div className="tax-para mt-4">
//               <p>
//                 Note: Finish your process by submitting a digitally signed W9
//                 form for automatic approval.
//               </p>
//               <p>
//                 Please note that the W9 form is necessary to complete your first
//                 purchase with us with Tax Exemption.
//               </p>
//             </div> */}
//           </div>
//         </section>
//         <p className="text-red-600">{errorMessage}</p>
//         <div className="flex justify-center md:justify-end py-2">

//           {(buttonClicked && errorMessage === "") ? (<button
//             type="button"
//             className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
//           >
//             <svg
//               aria-hidden="true"
//               role="status"
//               className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
//               viewBox="0 0 100 101"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
//                 fill="currentColor"
//               />
//               <path
//                 d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
//                 fill="#1C64F2"
//               />
//             </svg>
//             Please Wait
//           </button>) : (<button
//             // className="subBtn text-white py-2 px-12 bg-blue-600"
//             className={`${errorMessage !== "" ? "bg-white rounded-lg border-2 border-slate-200" : "subBtn text-white"}  py-2 px-12`}

//             disabled={errorMessage !== ""}>
//             Next
//           </button>)}


//         </div>
//       </form>
//     </div>
//   );
// }

// export default RegistrationForm;







import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { parsePhoneNumber } from 'awesome-phonenumber';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import * as EmailValidator from 'email-validator';
import { GoInfo } from "react-icons/go";
import { CiCircleCheck } from "react-icons/ci";
import { expoUrl } from "../config/config";



function UsaRegistrationForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ownerName: "",
    business: "",
    businessName: "",
    street1: "",
    street2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "US",
    phone: "",
    addressType: "",
    dockDoor: "no",
    firstName: "",
    lastName: "",
    primaryPhone: "",
    primaryEmail: "",
    taxId: "",
    ssn: "",
  });
  console.log(formData);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false)
  // const [einClicked, setEinClicked] = useState(true)
  // const [ssnClicked, setSsnClicked] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [isSoleProprietorship, setIsSoleProprietorship] = useState(false)
  const [companyFound, setCompanyFound] = useState(false);
  const [companyFoundMessage, setCompanyFoundMessage] = useState("");

  console.log("Company found state ", companyFound);

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem("formData"));
    if (storedFormData) {
      setFormData(storedFormData);
    }
  }, []);
  useEffect(() => {
    if (errorMessage !== "") {
      setButtonClicked(false);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (formData.business === "sole-proprietorship") {
      setIsSoleProprietorship(true);
      
    }
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    if ((id === 'taxId' || id === 'ssn') && value.length === 9) {
      searchCompanyAPI(value);
    }

    if (id === "business") {
      if (value === "sole-proprietorship") {
        setIsSoleProprietorship(true);
      } else {
        setIsSoleProprietorship(false);
      }
    }

    if (id === "primaryPhone" || id === "phone") {
      const phoneNumber = value.replace(/\D/g, "").slice(0, 12);
      setFormData(prevFormData => ({ ...prevFormData, [id]: phoneNumber }));
    } else if (id === "zipCode") {
      const zipCode = value.replace(/\D/g, "").slice(0, 5);
      setFormData(prevFormData => ({ ...prevFormData, [id]: zipCode }));
    } else {
      setFormData(prevFormData => ({ ...prevFormData, [id]: value }));
    }
    
    setErrorMessage("");
  };


  const searchCompanyAPI = async (num) => {
    setLoading(true);
    try {
      const response = await axios.get(`${expoUrl}/api/usa/companies/companysearch/${num}`);
      setCompanyFound(response.data.success);
      setCompanyFoundMessage(response.data.message)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const sendDataToSearchAPI = async () => {
    try {
      const response = await axios.post(
        `${expoUrl}/api/usa/companies/search`,
        {
          email: formData.primaryEmail,
          phone: formData.primaryPhone,
        }
      );
      console.log("Search API Response:", response?.data?.success);
      if (response?.data?.success === true) {
        navigate("/usa/confirmation", { state: { formData } });
      } else {
        setErrorMessage(response?.data?.message);
      }
    } catch (error) {
      console.error("Error sending data to search API:", error);
    }
  };

  // const handleCheckboxChange = (e) => {
  //   setIsChecked(!isChecked);

  //   if (e.target.checked) {
  //     setFormData({ ...formData, primaryPhone: formData.phone });
  //   } else {
  //     setFormData({ ...formData, primaryPhone: "" });
  //   }
  // };

  const handleSubmit = async (e) => {
    setButtonClicked(true);
    e.preventDefault();
    // console.log("formdata", formData);
    // if (formData.password !== formData.confirmPassword) {
    //   setErrorMessage("Please fill same password");
    //   return;
    // }

    // if (formData.otherBusinessType != "" && formData.business === "sole-proprietorship") {
    //   formData.business = formData.otherBusinessType;
    // }

    const pn = parsePhoneNumber(formData.primaryPhone, { regionCode: 'US' });
    if (pn.valid === false) {
      setErrorMessage("Invalid phone number, Please fill valid US number");
      return;
    }
    const validMail = EmailValidator.validate(formData.primaryEmail);
    console.log(validMail);
    if (validMail === false) {
      setErrorMessage("Invalid email address, Please fill valid email");
      return;
    }

    const zipValid = postcodeValidator(formData.zipCode, 'US');
    if (zipValid === false) {
      setErrorMessage("Invalid Zipcode, Please fill valid US Zipcode");
      return;
    }
    console.log("Zip code authentication=====>>>>>", zipValid);
    if (formData.business === "sole-proprietorship") {
      formData.taxId = ""
    } else if (formData.business !== "sole-proprietorship") {
      formData.ssn = ""
      formData.ownerName = "";
    }

    if (formData.business === "sole-proprietorship" && formData.businessName === "") {
      formData.businessName = formData.ownerName;
      formData.ownerName="";
    }

    // searchCompanyAPI();
    sendDataToSearchAPI();
    // navigate("/usa/confirmation", { state: { formData } });
  };


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isRedirect = queryParams.get('redirect') === 'true';

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-center text-2xl  mt-4 mb-8">Create a Business Account</h1>
      {isRedirect && (
        <section className="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">

          <p className="text-red-500">The email you entered is not registered or approved for access. Please check your email for accuracy,<br></br> or contact <span className="font-bold text-red-500">support@expobazaar.com</span> for assistance.</p>
          {/* <p className="font-bold text-red-500">You are not a registered user</p>
          <p className="text-red-500">Please register here to continue</p> */}
        </section>
      )}
      <section className="border p-4 md:p-8 mb-6 rounded-lg businessInfo">
        <p className="mb-4">
          As a Buyer, you'll enjoy exclusive pricing and access to a wide range of selected home and lifestyle brands. Our unique collection combines designs and patterns inspired by Indian artistry, offering products with global appeal.
        </p>
        <p className="mb-4">
          Our dedicated sales team will work with you to curate home and lifestyle products that meet your specific sourcing requirements.
        </p>
        <p className="mb-0">
          To speed up registration, please have the following information ready:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <b>Company and contact details</b>

          </li>
          <li>
            <b>Billing and tax information</b>
          </li>
        </ul>
        <p className="mb-4">
          For common questions, please visit our{" "}
          <a href="https://expobazaarus.myshopify.com/pages/faq" className="underline">
            <b>FAQ page.</b>
          </a>{" "}
          If you're an existing ExpoBazaar customer and need help, email us at{" "}
          <a href="mailto:info@expobazaar.com" className="underline">
            <b>info@expobazaar.com</b>
          </a>
          . For any other inquiries, please submit a request.
        </p>
        <p className="">
          <b>Important: </b> To receive tax exemption, you'll need to digitally sign a W9 form during registration process.
        </p>
      </section>

      <form className="space-y-6" onSubmit={handleSubmit}>
        <section className="pb-6">
          <h2 className="text-xl font-semibold mb-4">Company Details</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">

            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="business"
              >
                Business Type*
              </label>
              <select
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                id="business"
                value={formData.business}
                onChange={handleChange}
                required
              >
                <option value="" disabled hidden>
                  Select Business Type
                </option>
                <option value="sole-proprietorship">Sole proprietorship</option>
                <option value="c-corporation">C Corporation</option>
                <option value="s-corporation">S Corporation</option>
                <option value="partnership">Partnership</option>
                <option value="trust/estate">Trust/Estate</option>
                <option value="llc">LLC</option>
                {/* <option value="other">Other</option> */}
              </select>
            </div>

            {/* {(formData.business === sole - proprietorship) ? (<div className="flex flex-col">
            </div>) : (<div className="flex flex-col">
              <div className="flex items-center formLables">
                <label
                  className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                  htmlFor="businessName"
                >
                  {isSoleProprietorship === true ? "Business Name*" : "Business Name"}
                </label>

                <input
                  className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                  id="businessName"
                  type="text"
                  placeholder="Enter Business Name"
                  value={formData.businessName}
                  onChange={handleChange}
                  required={isSoleProprietorship === true}
                />
              </div>)} */}

            {formData.business === "sole-proprietorship" ? (
              <div className="flex items-baseline formLables">
                <label
                  className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                  htmlFor="ownerName"
                >
                  Owner Name*
                </label>
                <input
                  className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                  id="ownerName"
                  type="text"
                  placeholder="Enter Owner Name"
                  value={formData.ownerName}
                  onChange={handleChange}
                  required
                />
              </div>
            ) : (
              <div className="flex flex-col">
                {/* Your content for sole proprietorship can go here if needed */}
              </div>
            )}

            <div className="flex flex-col">
              <div className="flex items-center formLables">
                <label
                  className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                  htmlFor="businessName"
                >
                  Business Name
                </label>

                <input
                  className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                  id="businessName"
                  type="text"
                  placeholder="Enter Business Name"
                  value={formData.businessName}
                  onChange={handleChange}
                // required={isSoleProprietorship === true}
                />
              </div>
              <div className="text-[10px] opacity-[50%] font-light pt-2 w-[65%] ml-[25%] block md:ml-[35%]">
                {isSoleProprietorship === true ? (<p>If different from owner's name</p>
                ) : (null)}
              </div>
            </div>
          </div>




        </section>

        <section className="pb-6">
          <h2 className="text-xl font-semibold mb-4">Billing Address</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="street1"
              >
                Street 1*
              </label>
              <input
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs "
                id="street1"
                type="text"
                placeholder="Enter Street 1"
                value={formData.street1}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="street2"
              >
                Street 2
              </label>
              <input
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                id="street2"
                type="text"
                placeholder="Enter Street 2"
                value={formData.street2}
                onChange={handleChange}
              />
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="zipCode"
              >
                Zip Code*
              </label>
              <input
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                id="zipCode"
                type="text"
                placeholder="Enter Zip Code"
                value={formData.zipCode}
                onChange={handleChange}
                required
              // maxLength={5}
              />
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="city"
              >
                City*
              </label>
              <input
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                id="city"
                type="text"
                placeholder="Enter City"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="state"
              >
                State*
              </label>
              <select
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                id="state"
                value={formData.state}
                onChange={handleChange}
                required
              >
                <option>Select State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="country"
              >
                Country*
              </label>

              <input
                className="w-full bg-slate-200 md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                id="phone"
                type="text"
                readOnly
                value="United States"
              />
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="phone"
              >
                Phone*
              </label>
              <input
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                id="phone"
                type="text"
                placeholder="Enter Phone Number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="dockDoor"
              >
                <p className="">Dock Door</p>
              </label>
              <select
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                id="dockDoor"
                value={formData.dockDoor}
                onChange={handleChange}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
        </section>

        <section className="pb-6">
          <h2 className="text-xl font-semibold mb-4">Primary Contact</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="firstName"
              >
                First Name*
              </label>
              <input
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                id="firstName"
                type="text"
                placeholder="Enter First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-center formLables">
              <label
                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                htmlFor="lastName"
              >
                Last Name*
              </label>
              <input
                className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2  text-xs"
                id="lastName"
                type="text"
                placeholder="Enter Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-center flex-col">
              <div className="flex items-center flex-row w-[100%]">
                <label
                  className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                  htmlFor="primaryPhone"
                >
                  Phone*
                </label>
                <input
                  className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                  id="primaryPhone"
                  type="text"
                  placeholder="Enter Phone Number"
                  value={formData.primaryPhone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="flex items-center flex-col ">
              <div className="flex items-center flex-row w-[100%]">
                <label
                  className="block text-sm font-medium mb-1 w-[35%] md:w-[35%]"
                  htmlFor="primaryEmail"
                >
                  Email*
                </label>
                <input
                  className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                  id="primaryEmail"
                  type="email"
                  placeholder="Enter Email"
                  value={formData.primaryEmail}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div className="tax-radio-container flex justify-start  align-middle md:gap-28 flex-row">
            <div>
              <h2 className="text-xl font-semibold mb-4">Tax Information</h2>
            </div>
            <div className="flex flex-row">
              <div
                className={`w-[20px] h-[20px] rounded-full ${isSoleProprietorship === true ? 'border-[1px] bg-slate-300 ' : 'border-[5px]'} border-[#535454]`}

              >              </div>
              <div className="pl-5"><p>Federal Tax ID/EIN No.</p></div>
            </div>
            <div className="flex flex-row justify-center align-middle">
              <div
                className={`w-[20px] h-[20px] rounded-full ${isSoleProprietorship === true ? 'border-[5px]' : 'border-[1px] bg-slate-300'} border-[#535454]`}

              >               </div>
              <div className="pl-5"><p>SSN No.</p></div>

            </div>
          </div>
          <div className="border rounded-lg p-4 md:p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">




              {(isSoleProprietorship === true) ? (
                <div className="flex items-center formLables">
                  <label
                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                    htmlFor="ssn"
                  >
                    SSN No.*
                  </label>
                  <input
                    className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                    id="ssn"
                    type="text"
                    placeholder="Enter SSN No."
                    value={formData.ssn}
                    onChange={handleChange}
                    required
                    maxLength={9}
                    minLength={9}

                  />
                </div>
              ) : (
                <div className="flex items-center formLables">
                  <label
                    className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                    htmlFor="taxId"
                  >
                    Federal Tax ID/EIN No.*
                  </label>
                  <input
                    className="w-full md:w-2/3 border border-[#535454] rounded px-3 py-2 text-xs"
                    id="taxId"
                    type="text"
                    placeholder="Enter Federal Tax ID/EIN No."
                    value={formData.taxId}
                    onChange={handleChange}
                    required
                    maxLength={9}
                    minLength={9}

                  />
                </div>
              )}

              {loading && <p>Verifying...</p>}
              <div className="py-2">
                {(formData.ssn.length === 9 || formData.taxId.length === 9) && (!loading) && (
                  companyFound ? (
                    <GoInfo className="text-red-500" size={20} />
                  ) : (
                    <CiCircleCheck className="text-green-500" size={20} />
                  )
                )}
              </div>
              {/* {loading && <p>Verifying...</p>}
              <div className="py-2">              
                { loading === false ?(companyFound ? (<GoInfo className="text-red-500" size={20}/>):(<CiCircleCheck className="text-green-500" size={20}/>)):(null) }
              </div> */}
              {/* {error && <p style={{ color: 'red' }}>{error}</p>}
              {isVerified && <p style={{ color: 'green' }}>Number verified!</p>} */}
            </div>
          </div>
        </section>
        {companyFound && <section class="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">
          <p className=" text-xs text-red-500">{companyFoundMessage}</p>
        </section>
        }

        <p className="text-red-600">{errorMessage}</p>
        <div className="flex justify-center md:justify-end py-2">

          {(buttonClicked && errorMessage === "") ? (<button
            type="button"
            className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
          >
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#1C64F2"
              />
            </svg>
            Please Wait
          </button>) : (<button
            className={`${errorMessage !== "" ? "bg-white rounded-lg border-2 border-slate-200" : "bg-[#535454] text-white border-[1px] rounded-[10px] border-[#535454] hover:bg-white hover:text-[#535454] hover:font-semibold"}  py-2 px-12`}

            disabled={errorMessage !== "" || companyFound === true}>
            Next
          </button>)}


        </div>
      </form>
    </div>
  );
}

export default UsaRegistrationForm;