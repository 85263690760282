import React, { createContext, useContext, useState } from 'react';

const FlagContext = createContext();

export const FlagProvider = ({ children }) => {
  const [selectedFlag, setSelectedFlag] = useState('dutch');
  const [urlFlagName, setUrlFlagName] = useState('nl');

  return (
    <FlagContext.Provider value={{ selectedFlag, setSelectedFlag, urlFlagName, setUrlFlagName }}>
      {children}
    </FlagContext.Provider>
  );
};

export const useFlag = () => {
  return useContext(FlagContext);
};
