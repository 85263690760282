import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/expoLogo.png';
import europeLogo from '../assets/expoLogoEurope.png';
import usaFlag from '../assets/Flag_of_the_United_States 1.png';
import indiaFlag from '../assets/india-flag.png';
import germanyFlag from '../assets/germanyFlag.png';
import franceFlag from '../assets/franceFlag.png';
import italyFlag from '../assets/italyFlag.png';
import spainFlag from '../assets/spainFlag.png';
import netherlandsFlag from '../assets/netherlandsFlag.png';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { FaCaretUp } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { useFlag } from '../contexts/FlagContext'; // Import the useFlag hook
import { useTranslation } from 'react-i18next';



const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { selectedFlag, setSelectedFlag } = useFlag(); // Use the context
  const [flagName, setFlagName] = useState("NL");
  // const [urlFlagName, setUrlFlagName] = useState("nl");
  const { urlFlagName, setUrlFlagName } = useFlag(); // Use the context
  console.log("URL FLAG NAME IS :", urlFlagName);

  const [isOpen, setIsOpen] = useState(false);


  console.log("FLAGNAME IS :", flagName)
  const navigate = useNavigate();
  const location = useLocation();

  const flags = [
    { code: 'english', name: 'EN', img: usaFlag },
    { code: 'dutch', name: 'NL', img: netherlandsFlag },
    { code: 'french', name: 'FR', img: franceFlag },
    { code: 'german', name: 'DE', img: germanyFlag },
    { code: 'spanish', name: 'ES', img: spainFlag },
    { code: 'italian', name: 'IT', img: italyFlag },
  ];
  const handleFlagSelect = (code, name) => {
    setSelectedFlag(code);
    setFlagName(name)
    setIsOpen(false);
  };

  useEffect(() => {
    if (flagName) {
      const lowerCaseFlagName = flagName.toLowerCase();
      setUrlFlagName(lowerCaseFlagName);  // Update urlFlagName
    }
  }, [flagName]); // This effect runs every time flagName changes



  const filteredFlags = flags.filter(flag => flag.code !== selectedFlag)

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const handleBackToSignBtn = () => {
    if (flagname === "Europe") {
      window.location.href = "https://eu.expobazaar.com/account";
    } else if (flagname === "India") {
      window.location.href = "https://expobazaarin.myshopify.com/account";
    } else {
      window.location.href = "https://expobazaarus.myshopify.com/customer_identity/logout";
    }

  }
  const handleGetInTouchBtn = () => {
    if (flagname === "Europe") {
      // window.location.href = `https://eu.expobazaar.com/${urlFlagName}/pages/contact?_ab=0&_fd=0&_sc=1&key=257e47c509c316244980e5128dbd477cf58d43cf396eb29dfb27335314c58b4f&preview_theme_id=124352069704`;
      const url = urlFlagName === "en"
        ? `https://eu.expobazaar.com/pages/contact?_ab=0&_fd=0&_sc=1&key=257e47c509c316244980e5128dbd477cf58d43cf396eb29dfb27335314c58b4f&preview_theme_id=124352069704`
        : `https://eu.expobazaar.com/${urlFlagName}/pages/contact?_ab=0&_fd=0&_sc=1&key=257e47c509c316244980e5128dbd477cf58d43cf396eb29dfb27335314c58b4f&preview_theme_id=124352069704`;

      window.location.href = url;
    } else if (flagname === "India") {
      window.location.href = 'https://expobazaarin.myshopify.com/pages/contact';
    } else {
      window.location.href = 'https://expobazaarus.myshopify.com/pages/contact';
    }

  }
  const goToHomePage = () => {
    if (flagname === "Europe") {
      window.location.href = 'https://eu.expobazaar.com';
    } else if (flagname === "India") {
      window.location.href = 'https://expobazaarin.myshopify.com';
    } else {
      window.location.href = 'https://expobazaarus.myshopify.com/customer_identity/logout';
    }

  };

  // const path = location.pathname;
  // let flagname = '';
  // if (path.includes('/usa')) {
  //   flagname = 'USA';
  // } else if (path.includes('/india')) {
  //   flagname = 'India';
  // } else if (path.includes('/europe')) {
  //   flagname = 'Europe';
  // } else {
  //   flagname = '';
  // }

  const path = location.pathname;
  // const searchParams = new URLSearchParams(location.search);
  let flagname = '';
  // let language = '';

  // Determine the flagname based on the path
  if (path.includes('/usa')) {
    flagname = 'USA';
  } else if (path.includes('/india')) {
    flagname = 'India';
  } else if (path.includes('/europe')) {
    flagname = 'Europe';
  } else {
    flagname = '';
  }

  // language = (searchParams.get('lang') || '').toUpperCase();

  // // You can now use both flagname and language as needed
  // console.log('Flagname:', flagname);
  // console.log('Language:', language);
  // if(language !== ''){
  //   setSelectedFlag(language);
  // }
  useEffect(() => {
    // Save flagName to localStorage
    localStorage.setItem("translation-lab-lang", flagName);
  }, [flagName]);

  useEffect(() => {
    const path = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    let language = '';

    // Get the value of the 'lang' parameter from the URL
    language = (searchParams.get('lang') || '').toUpperCase();

    // Find the flag code and name for the given language
    const findFlagByLanguage = (language) => {
      const flag = flags.find(flag => flag.name === language);
      return flag ? { code: flag.code, name: flag.name } : { code: '', name: '' };
    };

    const { code: languageCode, name: languageName } = findFlagByLanguage(language);

    // Set the selected flag and flag name based on the language
    if (languageCode) {
      setSelectedFlag(languageCode);
      setFlagName(languageName);
    }

    // }, [location.pathname, location.search, setSelectedFlag, flags]);
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <header className={`${flagname === "Europe" ? "bg-white " : "bg-white text-white border-b-2 border-[#F8F6F4]"}`}>

      <div className="header-container flex flex-wrap items-center px-[12%]  justify-center  flex-row md:flex-row w-[100%] md:justify-between">
        {/* <div className="searchContainer  items-center space-x-2 px-2 rounded-full md:space-x-4 p-1 ">
          <input
            type="text"
            placeholder="Search"
            className="searchBar text-white  px-4 py-1 rounded"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="text-white search-icon "
          />
        </div> */}
        <div className="flex justify-center items-center w-[100%] md:w-[40%]">
          <div className='w-[50%] flex justify-start'>
            <button
              className={`${flagname === "Europe" ? "border border-[2px] border-[#548091] md:h-[46px]  text-[#548091] hover:bg-[#548091] hover:text-white"
                : flagname === "India" ? "border border-[2px] border-[#176B87] md:h-[46px]  text-[#176B87] hover:bg-[#176B87] hover:text-white"
                  : "bg-[#535454] text-white border-[1px] border-[#535454] hover:bg-white hover:text-[#535454] md:h-[45px]"} flex justify-center items-center  w-[110px] h-[25px] md:w-[157px]  font-semibold rounded-3xl`}
              onClick={handleGetInTouchBtn}
            >
              {flagname === "Europe" ? <p className='text-xs md:text-sm'>{t('header.getInTouch')}</p>
                : <p className='text-xs md:text-sm'>GET IN TOUCH</p>}

            </button>
          </div>
          <div className='w-[50%] flex justify-end'>
            <button
              className={`${flagname === "Europe" ? "bg-[#548091] text-white border-[2px] border-[#548091]  hover:bg-white hover:text-[#548091]"
                : flagname === "India" ? "bg-[#176B87] text-white border-[2px] border-[#176B87]  hover:bg-white hover:text-[#176B87]"
                  : "bg-[#535454] text-white border-[1px] border-[#535454] hover:bg-white hover:text-[#535454]"} w-[120px] h-[25px] md:w-[157px] md:h-[45px] font-semibold rounded-3xl md:hidden`}
              onClick={handleBackToSignBtn}
            >
              {flagname === "Europe" ? <p className='text-xs md:text-sm'>{t('header.backToSignIn')}</p>
                : <p className='text-xs md:text-sm'>BACK TO SIGN IN</p>}
            </button>
          </div>
        </div>
        <div className={`flex ${flagname === '' ? 'justify-center' : 'justify-between'} md:justify-between w-[100%] md:w-[56.5%] mt-0`}>
          <div className="header-logo-container cursor-pointer  md:font-bold flex items-center" onClick={goToHomePage}>
            {flagname === "Europe" ? <img src={europeLogo} alt="Logo" className="header-logo w-[150px]  md:w-[200px]" /> : <img src={europeLogo} alt="Logo" className="header-logo w-[150px]  md:w-[200px]" />}
          </div>
          <div className="flex justify-center header-right-container flex items-center space-x-2    md:flex md:space-x-4">
            {flagname && (
              <>
                {flagname === "USA" && (
                  <div className="flex justify-center items-center space-x-1">
                    <span role="img" aria-label="USA flag" className='w-[27px] h-[16px]'>
                      <img src={usaFlag} alt="USA Flag" className="h-3 md:h-[100%] w-[100%]" />
                    </span>
                    <span className='font-bold pl-1 md:pr-5 text-[#535454]'>{flagname}</span>
                  </div>
                )}
                {flagname === "India" && (
                  <div className="flex justify-center items-center space-x-1">
                    <span role="img" aria-label="India flag" className='w-[27px] h-[16px]'>
                      <img src={indiaFlag} alt="India Flag" className="h-3 md:h-[100%] w-[100%]" />
                    </span>
                    <span className='font-bold pl-1 md:pr-5 text-[#176B87]'>{flagname}</span>
                  </div>
                )}
                {flagname === "Europe" && (
                  <div className="relative inline-block text-left">
                    <div>
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className={`inline-flex justify-start w-24 ${isOpen ? 'shadow-sm shadow-grey' : ''} px-4  py-2 text-white text-sm font-medium text-gray-700 focus:outline-none`}
                        aria-haspopup="true"
                        aria-expanded={isOpen}
                      >
                        <img src={flags.find(flag => flag.code === selectedFlag).img} alt={`${selectedFlag} Flag`} className="w-6 h-3 mt-[3px] mr-2" />
                        <p className={`${flagname === "Europe" ? 'europeHeaderTextColour' : 'text-white'}`}>{flagName}</p>
                        <div className={`${flagname === "Europe" ? 'europeHeaderTextColour' : 'text-white'} px-1`}>
                          {isOpen ? <FaCaretUp size={17} /> : <FaCaretDown size={17} />}
                        </div>
                      </button>
                    </div>

                    {isOpen && (
                      <div className="origin-top-right absolute right-0 w-24 shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          {filteredFlags.map(flag => (
                            <button
                              key={flag.code}
                              onClick={() => handleFlagSelect(flag.code, flag.name)}
                              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                              role="menuitem"
                            >
                              <img src={flag.img} alt={`${flag.name} Flag`} className="w-6 h-4 mr-2" />
                              {flag.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {/* <div className="relative inline-block text-left">
              <div>
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className={`inline-flex justify-start w-24 ${isOpen ? 'shadow-sm shadow-black' : '' } px-4  py-2 text-white text-sm font-medium text-gray-700 focus:outline-none`}
                  aria-haspopup="true"
                  aria-expanded={isOpen}
                >
                  <img src={flags.find(flag => flag.code === selectedFlag).img} alt={`${selectedFlag} Flag`} className="w-6 h-4 mr-2" />
                  {flagName}
                  <div className='px-1'>
                  {isOpen ?<FaCaretUp size={17}/> :<FaCaretDown size={17}/>}
                  </div>
                </button>
              </div>

              {isOpen && (
                <div className="origin-top-right absolute right-0 w-24 shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {filteredFlags.map(flag => (
                      <button
                        key={flag.code}
                        onClick={() => handleFlagSelect(flag.code, flag.name)}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                        role="menuitem"
                      >
                        <img src={flag.img} alt={`${flag.name} Flag`} className="w-6 h-4 mr-2" />
                        {flag.name}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div> */}


            <div className='flex justify-center hidden items-center md:block'>
              <button
                // className={`${flagname === "Europe" ? "bg-[#548091] text-white md:h-[30px]" : "bg-[#535454] text-white border-[1px] border-[#535454] hover:bg-white hover:text-[#535454] md:h-[45px]"} w-[120px] h-[25px] md:w-[157px]  font-semibold rounded-3xl`}
                // className={`${flagname === "Europe" ? "bg-[#548091] text-white border-[2px] border-[#548091] md:h-[46px] hover:bg-white hover:text-[#548091]" : "bg-[#535454] text-white border-[1px] border-[#535454] hover:bg-white hover:text-[#535454] md:h-[45px]"} w-[120px] h-[25px] md:w-[200px]  font-semibold rounded-3xl`}
                className={`${flagname === "Europe"
                  ? "bg-[#548091] text-white border-[2px] border-[#548091] md:h-[46px] hover:bg-white hover:text-[#548091]"
                  : flagname === "India"
                    ? "bg-[#176B87] text-white border-[2px] border-[#176B87] md:h-[46px] hover:bg-white hover:text-[#176B87]"
                    : "bg-[#535454] text-white border-[1px] border-[#535454] hover:bg-white hover:text-[#535454] md:h-[45px]"} 
                  w-[120px] h-[25px] md:w-[200px] font-semibold rounded-3xl`}

                onClick={handleBackToSignBtn}
              >
                {flagname === "Europe" ? <p className='text-xs md:text-sm'>{t('header.backToSignIn')}</p>
                  : <p className='text-xs md:text-sm'>BACK TO SIGN IN</p>}
              </button>
            </div>
            {/* <div>
              <button className="ml-6 md:hidden" onClick={toggleNav}>
                <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} className="text-white" />
              </button>
            </div> */}
          </div>
        </div>

      </div>
      {/* <nav className={`navBar text-white py-2 px-4 flex  md:px-12  border-t ${isNavOpen ? 'block' : 'hidden'} md:flex justify-center`}>
        <ul className="flex flex-wrap justify-evenly space-x-2 md:space-x-4 navArea w-[80%] ">
          <li>
            <select className="header-dropdown">
              <option>Home Decor & Accent</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </li>
          <li>
            <select className="header-dropdown">
              <option>Kitchen Dining</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </li>
          <li>
            <select className="header-dropdown">
              <option>Home Furnishing</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </li>
          <li>
            <select className="header-dropdown">
              <option>Furniture</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </li>
          <li>
            <select className="header-dropdown">
              <option>Fashion & Accessories</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </li>
          <li>
            <select className="header-dropdown">
              <option>Kids & Babies</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </li>
          <li>
            <select className="header-dropdown">
              <option>Gifts Packing & Stationary</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </li>
        </ul>
      </nav> */}
    </header >
  );
};

export default Header;
