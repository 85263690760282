// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   BrowserRouter,
// } from "react-router-dom";
// import RegistrationForm from "../pages/registrationForm";
// import ConfirmRegistration from "../pages/confirmRegistration";
// import Home from "../pages/home";
// import Header from "../components/header";
// import Footer from "../components/footer";
// import Notfound from "../pages/notFound";
// import lastPage from "../pages/lastPage";

// function RoutePage() {
//   return (
//     <BrowserRouter>
//     <Header/>
//       <Routes>
//         <Route path="/" element={ <Home/> } />
//         <Route path="/registration" exact element={<RegistrationForm/>} />
//         <Route path="/confirmation" element={<ConfirmRegistration/>} />
//         <Route path="*" element={<Notfound/>} />
//       </Routes>
//       <Footer/>
//       <Route path="/lastpage" element={<lastPage/>} />
//     </BrowserRouter>
//   );
// }

// export default RoutePage;


import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
// import usaRegistrationForm from "../pages/usaRegistrationForm";
// import usaConfirmRegistration from "../pages/usaConfirmRegistration";
import Home from "../pages/home";
import Header from "../components/header";
import Footer from "../components/footer";
import Notfound from "../pages/notFound";
import Thankyou from "../pages/thankyou";
import UsaRegistrationForm from "../pages/usaRegistrationForm";
import UsaConfirmRegistration from "../pages/usaConfirmRegistration";
import EuropeRegistrationForm from "../pages/europeRegistrationForm";
import 'react-tooltip/dist/react-tooltip.css'
import IndiaRegistrationForm from "../pages/indiaRegistrationForm";

function RoutePage() {
  const location = useLocation();

  const showHeaderFooter = location.pathname !== "/lastpage";

  return (
    <>
      {showHeaderFooter && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/usa/registration" element={<UsaRegistrationForm />} />
        <Route path="/usa/confirmation" element={<UsaConfirmRegistration />} />
        <Route path="/europe/registration" element={<EuropeRegistrationForm />} />
        <Route path="/india/registration" element={<IndiaRegistrationForm />} />

        {/* <Route path="/thankyou" element={<Thankyou />} /> */}
        <Route path="*" element={<Notfound />} />
      </Routes>
      {showHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <RoutePage />
    </Router>
  );
}

export default App;
