import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Home = () => {
  let navigate = useNavigate();

  // useEffect(() => {
  //   navigate('/usa/registration');
  // }, [navigate]);

  return (
    <div className="flex flex-col flex-wrap w-full min-h-[70vh] my-7 px-5 justify-evenly items-center gap-4 md:flex-row">
      <Link to="/usa/registration">
        <div className="w-72 h-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 hover:bg-slate-100">
          <div className="flex justify-center pb-5 text-gray-900 dark:text-white">
            <p className="text-3xl font-medium tracking-tight md:text-5xl">USA</p>
          </div>
          <button
            type="button"
            className="subBtn text-white focus:outline-none font-medium rounded-lg text-sm py-2.5 inline-flex justify-center w-full text-center "
          >
            Sign Up
          </button>
        </div>
      </Link>
      <Link to="/europe/registration" >
        <div className="w-72 h-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 hover:bg-slate-100">
          <div className="flex justify-center pb-5 text-gray-900 dark:text-white">
            <p className="text-3xl md:text-5xl font-medium  tracking-tight">Europe</p>
          </div>
          <button
            type="button"
            className="subBtn text-white focus:outline-none font-medium rounded-lg text-sm  py-2.5 inline-flex justify-center w-full text-center"
          >
            Sign Up
          </button>
        </div>
      </Link>
      <Link to="/india/registration">
        <div className="w-72 h-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 hover:bg-slate-100">
          <div className="flex justify-center pb-5 text-gray-900 dark:text-white">
            <p className="text-3xl md:text-5xl font-medium  tracking-tight">India</p>
          </div>
          <button
            type="button"
            className="subBtn text-white focus:outline-none font-medium rounded-lg text-sm  py-2.5 inline-flex justify-center w-full text-center"
          >
            Sign Up
          </button>
              
        </div></Link>
    </div>
  );
};

export default Home;
